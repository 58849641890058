import React from 'react';
import { CreateBase, useRedirect, useResourceContext } from 'react-admin';
import SimpleFormCustom from '../../Components/Form/SimpleFormCustom';
import Form from '../Settings/Form';
import CreateHeader from '../../Components/Form/CreateHeader';

const transformCreate = (data) => {
  const currencies = data.currencies?.filter(item => item !== undefined);
  return ({ ...data, currencies });
};
const Create = () => {
  const redirect = useRedirect();
  const resource = useResourceContext();

  const goBack = () => {
    redirect(`/${resource}`);
  };

  return (
    <CreateBase mutationMode='optimistic' transform={transformCreate}>
      <SimpleFormCustom sx={{ display: 'flex', flexDirection: 'column',width:'100%' }} header={<CreateHeader goBack={goBack} label='Nuevo Comercio'/>}>
        <Form isCreate />
      </SimpleFormCustom>
    </CreateBase>
  );
};

export default Create;