import Create from './Create';
import Edit from './Edit';
import List from './List';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: StoreOutlinedIcon
};