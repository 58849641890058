import { Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';

const Card = ({
  children,
  title,
  icon,
  sx,
  border = false,
  sxTitle,
  sxHeader,
  isLoading,
  collapse
}) => {
  const [open, setOpen] = useState(true);
  const handleOpenDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '.25em',
        boxShadow: '0 .07em .125em 0 rgba(0,0,0,.15)',
        marginBottom: '24px',
       
        ...sxHeader,
      }}
    >
      {title && (
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            paddingLeft: '4px',
            borderBottom: border ? '1px solid #e6e6e6' : 'none',
            padding: '15px 18px',
          }}
        >
          {icon}
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'rgba(0,0,0,.72)',
              ...sxTitle,
            }}
          >
            {title}
          </Typography>
          {collapse && (
            <IconButton variant="outlined" sx={{justifySelf: 'flex-end'}} onClick={handleOpenDrawer}>
              {open ?  <IoIosArrowDropupCircle sx={{fontSize: '21px'}} /> :<IoIosArrowDropdownCircle />}
            </IconButton>
          )}
        </Box>
      )}
      {
        isLoading ? (
          <Box m={2}>
            <Skeleton sx={{height: '50px'}} />
            <Skeleton sx={{height: '50px'}} />
            <Skeleton sx={{height: '50px'}} />
          </Box>
        ) : (
          <Collapse sx={{width: 'auto'}} in={open} >
            <Box sx={{ padding: '18px 21px 12px 42px', ...sx }}>{children}</Box>
          </Collapse>
        )
      }
    </Box>
  );
};

export default Card;
