import React, { useEffect, useMemo} from 'react';
import { ShowBase, useGetOne, useRefresh, useShowContext, useUpdate } from 'react-admin';
import Card from '../../Components/Card/Card';
import { Box, Button, IconButton, Skeleton, Typography } from '@mui/material';
import WrapperShow from '../../Components/Show/WrapperShow';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import BackButton from '../../Components/Button/BackButton';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { calculateDate, copyText } from '../../utils/helpers';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useCurrencies } from '../../utils/helpers/useCurrencies';

const TYPE_STATUS = {
  approved: {
    color: 'green',
    icon: <CheckCircleRoundedIcon sx={{ fontSize: '21px', color: 'green' }} />,
    title: 'Aprobado'
  },
  error: {
    color: 'red',
    icon: <ErrorOutlineOutlinedIcon sx={{ fontSize: '21px', color: 'red' }} />,
    title: 'Error'
  },
  pending: {
    color: 'orange',
    icon: <PendingOutlinedIcon sx={{ fontSize: '21px', color: 'orange' }} />,
    title: 'Pendiente'
  },
  refunded: {
    color: 'gray',
    icon: <RestartAltOutlinedIcon sx={{ fontSize: '21px', color: 'gray' }} />,
    title: 'Devolución'
  },
  canceled: {
    color: 'red',
    icon: <CancelOutlinedIcon sx={{ fontSize: '21px', color: 'red' }} />,
    title: 'Cancelada'
  },
  overpaid: {
    color: 'purple',
    icon: <CancelOutlinedIcon sx={{ fontSize: '21px', color: 'red' }} />,
    title: 'Overpaid'
  },
  expired: {
    color: 'maroon',
    icon: <CancelOutlinedIcon sx={{ fontSize: '21px', color: 'maroon' }} />,
    title: 'Expirada'
  }
};

const Show = () => {

  return (
    <ShowBase resource='payment_requests'>
      <ShowActivity />
    </ShowBase>
  );
};

const ShowActivity = () => {
  const { record } = useShowContext();
  const { currencies, loading, error } = useCurrencies();
  

  const isCorrect = record?.status === 'approved' || record?.status === 'refunded';
  const tokenRecord = isCorrect && record?.transactions?.find(tx => (tx.status === 'correct' || tx.status === 'overpaid'));

  //Verificamos que tanto el TICKER como el CHAIN_ID sean iguales al del record. 
  const token = useMemo(() => {
    if (tokenRecord && currencies?.length > 0) {
      return currencies.find(
        (currency) => currency?.ticker.toLowerCase() === tokenRecord?.coin?.toLowerCase() && currency?.chain_id === tokenRecord?.chain_id
      );
    }
  }, [currencies, tokenRecord]);
  
  if (record) {
    return (
      <WrapperShow>
        <DateCreated record={record} />
        <PaymentAmount record={record} />

        <StatusPayment record={record} tokenRecord={tokenRecord} token={token} />

        {
          isCorrect && (
            <DetailsPayment record={record} tokenRecord={tokenRecord} token={token} />
          )
        }

        {
          <ToolbarActions record={record} />
        }
      </WrapperShow>
    );
  }
};

const DateCreated = ({ record }) => {
  const date = calculateDate(record.date_created).format('DD [de] MMMM - HH:mm [hs]');

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'center',marginBottom:'10px' }}>
      <Typography sx={{ fontSize: '15px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>Creada el {date}</Typography>
    </Box>
  );
};

const PaymentAmount = ({ record }) => {
  return (
    <Card
      title={'Pago'}
      icon={<AttachMoneyRoundedIcon sx={{ fontSize: '24px' }} />}
      sx={{ padding: '15px 18px' }}
      sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
      sxHeader={{ backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7', display:'flex',justifyContent:'space-between'}}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'right', gap: '6px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>$ {record.ars_amount} <span style={{ fontSize: '12px', fontWeight: '600' }}>ars</span></Typography>
      </Box>
    </Card>
  );
};

const StatusPayment = ({ record, tokenRecord, token }) => {
  const status = record.status;
  const isApproved = status === 'approved' || status === 'refunded' || (record.status_details === 'overpaid');
  const rate = isApproved && parseInt(tokenRecord.price_details.rate).toLocaleString();

  return (
    <Card 
      sx={{ padding: 0 }}
      sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
      sxHeader={{ backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7'}}
    >
      <Box sx={{
        padding: '21px 15px',
        borderBottom: '1px solid rgba(0,0,0,.15)',
        borderTopLeftRadius: '.25em',
        borderLeft: `4px solid ${TYPE_STATUS[status]?.color}`,
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        borderBottomLeftRadius: !isApproved && '.25em'
      }}
      >
        {
          TYPE_STATUS[status]?.icon
        }
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{TYPE_STATUS[status]?.title}</Typography>
      </Box>
      {
        isApproved && (
          <Box sx={{ padding: '21px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <PaymentOutlinedIcon sx={{ fontSize: '18px' }} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Medio de pago</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0 0 15px', marginBottom: '3px' }}>
              <Box sx={{ display: 'flex', gap: '21px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  {token?.logo ? <img src={token?.logo} style={{ width: '24px', height: '24px' }} /> : 
                    <Skeleton variant="circular" width={30} height={30} />
                  }
                  {token?.name ? <Typography sx={{ fontSize: '15px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>{token?.name}</Typography>
                    : 
                    <Skeleton variant="text" width={100} /> 
                  }
                </Box>
                {
                  token?.chain && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <img src={token?.chain?.logo} style={{ width: '15px', height: '15px' }} />
                      <Typography sx={{ fontSize: '12px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>{token?.chain?.name}</Typography>
                    </Box>
                  )
                }
              </Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, textAlign: 'right', gap: '6px' }}> {tokenRecord.price_details.final_amount} <span style={{ fontSize: '12px', fontWeight: '600' }}>{tokenRecord.coin}</span></Typography>
            </Box>
            <Typography sx={{ fontSize: '13px', fontWeight: '600', textAlign: 'right', color: 'rgba(0,0,0,.7)' }}>
              1  <span style={{ fontSize: '12px', fontWeight: '600' }}>{tokenRecord.coin}</span> = {rate} <span style={{ fontSize: '12px', fontWeight: '600' }}>ars</span>
            </Typography>
          </Box>
        )
      }
    </Card>
  );
};

const DetailsPayment = ({ tokenRecord, record }) => {
  const payment = tokenRecord.payments[0];
  const { data } = useGetOne('merchants', { id: record.merchant_id });

  const coinExplorer = {
    bch: 'bitcoin-cash',
    doc: 'rootstock',
    usdt: 'polygon'
  };

  const handleCopy = () => {
    copyText(payment.hash);
  };

  const handleClickExplorer = () => {
    const coin = tokenRecord.coin.toLowerCase();
    const link = coin !== 'uxd' ? `https://blockchair.com/${coinExplorer[coin]}/transaction/${payment.hash}` : `https://explorer.lachain.network/tx/${payment.hash}`;
    window.open(link, '_blank');
  };

  return (
    <Card 
      sx={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }} title={'Detalles'} 
      border
      sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
      sxHeader={{ backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7'}}
    >

      {
        payment?.paid_at && (
          <RowPayment title={'Fecha de pago'} value={calculateDate(payment.paid_at).format('DD [de] MMMM - HH:mm [hs]')} />
        )
      }

      <RowPayment
        title={'Negocio'}
        value={data?.name}
      />
      {
        (payment?.hash && tokenRecord?.coin?.toLowerCase() !== 'btc') && (
          <>
            <RowPayment
              title={'Hash'}
              value={payment.hash}
              rtl
              icon={
                <IconButton aria-label="delete" size="small" onClick={handleCopy}>
                  <ContentCopyRoundedIcon fontSize="inherit" />
                </IconButton>
              }
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '6px' }}>
              <Button
                size='small'
                variant={'text'}
                endIcon={<OpenInNewRoundedIcon sx={{ width: '14px' }} />}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none'
                }}
                onClick={handleClickExplorer}
              >
                Ver en el explorador
              </Button>
            </Box>
          </>
        )
      }

    </Card>
  );
};

const ToolbarActions = ({ record }) => {
  const [update, { isLoading, isSuccess, ...rest }] = useUpdate();
  const refresh = useRefresh();

  const isApproved = record?.status === 'approved';
  const isPending = record?.status === 'pending';
  const isTest = process.env.REACT_APP_DEVELOPMENT === 'true';

  const handleClick = (method, params = null) => {
    update('payment_requests', { id: `${record.id}/${method}/?${params}` });
  };

  useEffect(() => {
    if (isSuccess) {
      refresh();
    }
  }, [isSuccess]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
      {
        isApproved && (
          <Button
            variant='text'
            onClick={() => handleClick('refund')}
            disabled={isLoading}
          >
            Marcar como devuelto
          </Button>
        )
      }

      {
        isPending && (
          <Button
            variant='text'
            color='error'
            onClick={() => handleClick('cancel')}
            disabled={isLoading}
          >
            Cancelar intento
          </Button>
        )
      }
      {
        (!isApproved && isTest) && (
          <Button
            variant='text'
            onClick={() => handleClick('approve', 'coin=BTC')}
            disabled={isLoading}
          >
            Aprobar intento
          </Button>
        )
      }

    </Box>
  );
};

const RowPayment = ({ title, value, rtl, icon }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
      <Typography sx={{ fontSize: '15px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
        <Typography sx={{
          fontSize: '12px',
          fontWeight: '600',
          maxWidth: '300px',
          overflow: 'hidden',
          direction: rtl && 'rtl',
          textOverflow: 'ellipsis',
          color: 'rgba(0,0,0,.7)'
        }}
        >
          {value}
        </Typography>
        <Box sx={{ width: '30px' }}>
          {icon}
        </Box>
      </Box>
    </Box>
  );
};

export default Show;