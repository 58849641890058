import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { instance } from '../dataProvider';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

const setMerchantSlug = async () => {
  try{
    const merchant = await instance.get(`${BASE_PATH}/merchants/me`);
    localStorage.setItem('merchantSlug', merchant.data.slug);
  }catch{
    console.log('Error merchantslug');
  }
};

const authProvider = {
  login: async (token_url) =>  {
    const urlAuth = jwtDecode(token_url)?.url;
    const res = await axios.post(urlAuth);
    if(res.status === 201){
      const { data: {access_token}} = res;
      const tokenStringify = JSON.stringify(res.data?.access_token);
      localStorage.setItem('auth', tokenStringify);
      instance.defaults.headers.common['Authorization'] = `Bearer ${tokenStringify?.slice(1, -1)}`;
      const decodedToken = jwtDecode(access_token);
      localStorage.setItem('permissions', decodedToken['role']); 
      await setMerchantSlug();
      return Promise.resolve(decodedToken);
    }else{
      return Promise.reject();
    }
  },
  emailAuth: ({email}) => {
    return axios.post(`${BASE_PATH}/login`,{ email });
  },
  logout: () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('permissions');
    localStorage.removeItem('merchantSlug');
    return Promise.resolve();
  },
  checkAuth: () => {
    const authToken = localStorage.getItem('auth');
    instance.defaults.headers.common['Authorization'] = `Bearer ${authToken?.slice(1, -1)}`;
    const decodeToken = authToken && jwtDecode(authToken);
    if(decodeToken && decodeToken.id && decodeToken.role){
      return Promise.resolve();
    }else{
      return Promise.reject();
    }

  },
  checkError:  (error) => {
    const status = error?.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    const authToken = localStorage.getItem('auth');
    const decodeToken = authToken && jwtDecode(authToken);
    if(decodeToken){
      return decodeToken;
    }else{
      return Promise.reject();
    }
  },
  getPermissions: () => {
    const authToken = localStorage.getItem('auth');
    const decodeToken = authToken && jwtDecode(authToken);
    if(decodeToken){
      return decodeToken.role;
    }else{
      return Promise.reject();
    }
  }
};

export default authProvider;