import { COLORS } from '../../assets/constants/COLORS';
import CircleIcon from '@mui/icons-material/Circle';
import { Box,Alert } from '@mui/material';

const statusOptions = {
  approved: {
    label: 'Aprobada'
  },
  error: {
    label: 'Error'
  },
  pending: {
    label: 'Pendiente'
  },
  refunded: {
    label: 'Devolución'
  },
  canceled: {
    label: 'Cancelada'
  },
  expired: {
    label: 'Expirada'
  }
};
export const Indicator = ({sx,data}) => {
  const bgColor = ()=>{
    if(data !== undefined || data !== null){
      switch(data){
      case 'approved':
        return COLORS.table.indicators.background.approved;
      case 'canceled':
        return COLORS.table.indicators.background.rejected;
      case 'pending':
        return COLORS.table.indicators.background.pending;
      case 'refunded':
        return COLORS.table.indicators.background.draft;
      case 'expired':
        return COLORS.table.indicators.background.not_submitted;
      default:
      }
    }
  };
  const fontColor = ()=>{
    if(data !== undefined || data !== null){
      switch(data){
      case 'approved':
        return COLORS.table.indicators.fontColor.approved;
      case 'canceled':
        return COLORS.table.indicators.fontColor.rejected;
      case 'pending':
        return COLORS.table.indicators.fontColor.pending;
      case 'refunded':
        return COLORS.table.indicators.fontColor.draft;
      case 'expired':
        return COLORS.table.indicators.fontColor.not_submitted;
      default:
      }
    }
  };
  const translateStatus = ()=>{
    switch(data){
    case 'approved':
      return statusOptions.approved.label;
    case 'canceled':
      return statusOptions.canceled.label;
    case 'pending':
      return statusOptions.pending.label;
    case 'refunded':
      return statusOptions.refunded.label;
    case 'expired':
      return statusOptions.expired.label;
    default:
    }
  };
  
  const sxApp = {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    padding:'0px 8px',
    height:'auto',
    width:'auto',
    backgroundColor:bgColor(),
    borderRadius:'10px',
    marginTop:'2px',
    '& .MuiAlert-message':{
      color:fontColor(),
      fontWeight:700,
      fontSize:'14px',
      lineHeight:'16px'
    },
    '& .MuiAlert-icon':{
      width:'auto',
    }
  };
  return (
    <Box
      sx={{
        height:'35px',
        borderRadius: '12px',
        width: 'auto',
        maxWidth:'140px',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Alert sx={sxApp} icon={<CircleIcon sx={{fontSize:'12px',color:fontColor()}}/>}>
        {translateStatus()}
      </Alert>

    </Box>
  );
};
  