import { Box, Typography } from '@mui/material';
import React from 'react';

const InputLabel = ({ label, input, sx, labelSx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Typography sx={{ fontSize: '14px', color: '#000000', marginBottom:'8px', fontWeight: 600, ...labelSx }}>{label}</Typography>
      {input && input}
    </Box>
  );
};

export default InputLabel;