import React from 'react';
import { EditBase, useAuthProvider, useListContext, useNotify, useRedirect, useRefresh, useResourceContext } from 'react-admin';
import SimpleFormCustom from '../../Components/Form/SimpleFormCustom';

import CreateHeader from '../../Components/Form/CreateHeader';
import Form from '../Merchants/Form';
const Settings = () => {
  const { getIdentity } = useAuthProvider();
  const { merchant_id } = getIdentity();
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const onSuccess = () => {
    // refresh();
    notify('Configuración actualizada', { type: 'success' });
  };
  const goBack = () => {
    redirect(`/${resource}`);
  };

  return (
    <EditBase redirect="list" resource="merchants" id={merchant_id}  mutationOptions={{ onSuccess }}>
      <SimpleFormCustom sx={{ display: 'flex', flexDirection: 'column',width:'100%' }} header={<CreateHeader goBack={goBack} label='Configuración' backAction={false}/>}>
        <Form />
      </SimpleFormCustom>
    </EditBase>
  );
};

export default Settings;