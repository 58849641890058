import React, { useEffect, useState } from 'react';
import Card from '../../Components/Card/Card';
import InputLabel from '../../Components/Input/InputLabel';
import {
  BooleanInput,
  TextInput,
  useNotify,
  useRecordContext,
} from 'react-admin';
import Button from '../../Components/Button/Button';
import { Box, IconButton, Typography } from '@mui/material';
import Modal from '../../Components/Modal/Modal';
import QRCodeComponent from '../../Components/QRCode/QRCodeComponent';
import { checkAddress, copyText, createQR } from '../../utils/helpers';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PaymentOptions from '../../Components/PaymentOptions/PaymentOptions';
import { LinkCheckout } from './Components/LinkCheckout';
import { QrCodeCard } from './Components/QrCodeCard';

const Form = ({ isCreate }) => {
  return (
    <>
      <Card
        title={'Datos del negocio'}
        sxTitle={{
          fontSize: '20px',
          lineHeight: '27px',
          fontWeight: 600,
          color: '#000000',
        }}
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          padding: '0 0 12px 30px',
          width: '100%',
          flexDirection: 'column',
        }}
        sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '2em',
          }}
        >
          <InputLabel
            input={<TextInput source={'name'} label={''} />}
            label={'Nombre'}
          />
          <InputLabel
            input={<TextInput source={'direction'} label={''} />}
            label={'Dirección'}
          />
          <InputLabel
            input={<TextInput source={'google_maps_link'} label={''} />}
            label={'Google Maps URL'}
          />
        </div>
        <LinkCheckout />
      </Card>

      <PaymentOptions isCreate={isCreate} />

      {/* <Card title={'Configurar offramp'} border>
        <OfframpConfig />
      </Card> */}

      <QrCodeCard />
    </>
  );
};



export default Form;
