import { useState } from 'react';
import Card from '../../../Components/Card/Card';
import { Box, Typography } from '@mui/material';
import { TooltipButton } from './PaymentMethods';
import { NumberInput, useInput } from 'react-admin';
import { strCapitalize } from '../../../utils/helpers';
import { BsPercent } from 'react-icons/bs';
import InputLabel from '../../../Components/Input/InputLabel';


export const CampaignsInfo = () => {


  return (
    <Card title="Campañas">
      <CampaignComponent />
    </Card>
  );
};


const defaultValues = [
  // {
  //   key: 'coinbase',
  //   value: 0
  // },
  {
    name: 'ripio',
    discount: 0
  },
];
export const CampaignComponent = () => {
  // eventualmente seguro se tenga que hacer una petición para setear las campañas
  const [campaigns, setCampaigns] = useState(defaultValues);
  
  return (
    <Box sx={{
      width: '100%',
      gap: '10px',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        <Typography sx={{fontSize: '.9em', fontWeight: '600', textTransform: 'uppercase'}}>Campañas</Typography>
        <TooltipButton message="Descuentos fijos" />
      </Box>
      {campaigns && campaigns.map((item, index) => (
        <InputCampaign defaultValue={campaigns[index]}source={`campaigns[${index}]`} />
      ))}
    </Box>
  );
};
  
export const InputCampaign = ({item, source, defaultValue }) => {
  const { field } = useInput({ source, defaultValue, parse: (value) => { return ({...value, value: Number(value.discount)});}});
  
  return (
      
    <InputLabel 
      labelSx={{width: '100%'}} 
      label={strCapitalize(field.value.name)} 
      input={
        <NumberInput
          sx={{width: '100px'}} 
          type="number"
          max={90}
          min={0}
          InputProps={{
            endAdornment: <BsPercent size="25px" color="#4e4e4e" />
          }}
          InputLabelProps={{
            shrink: false,
            sx: {
              display: 'none'
            }
          }}
          defaultValue={field.value.discount} 
          source={`${source}.discount`}
        />} />
      
  );
};