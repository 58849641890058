import React from 'react';
import { ListButton } from 'react-admin';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

const BackButton = () => {
  return (
    <ListButton
      label={'volver'}
      icon={<KeyboardArrowLeftOutlinedIcon />}
      sx={{ fontSize: '12px', marginBottom: '12px' }}
      color='secondary'
    />
  );
};

export default BackButton;