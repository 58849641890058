import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useRedirect, useResourceContext, useShowContext } from 'react-admin';
import CreateHeader from '../Form/CreateHeader';

const WrapperShow = ({ children }) => {
  const { isLoading, error } = useShowContext();
  const redirect = useRedirect();
  const resource = useResourceContext();
  if (isLoading) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: '42px' }}>
        <CircularProgress size={'small'} style={{ width: '30px', height: '30px' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography>Error</Typography>
      </Box>
    );
  }
  const goBack= () => {
    redirect(`/${resource}`);
  };
  
  return (
    <Box sx={{ padding: '42px 21px', display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent: 'center', }}>
      <CreateHeader sx={{alignSelf: 'flex-start'}} goBack={goBack} label='Editar Pago' isEdit={true} onlyEditText={true}/>
      <Box sx={{ width: '100%', maxWidth: '800px'}}>
        {children}
      </Box>
    </Box>
  );
};

export default WrapperShow;