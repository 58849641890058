import { Button } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useListContext, useRedirect } from 'react-admin';

const CreateButton = ({ ...props }) => {
  const { resource } = useListContext();
  const redirect = useRedirect();
  return (
    <Button
      variant='contained'
      sx={{
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 600,
        textTransform:'none',
        color: '#fff',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:'5px',
        borderRadius:'10px',
        padding:'10px 20px'
      }}
      onClick={() => redirect(`/${resource}/create`)}
      {...props}
    >
      <AddIcon sx={{fontSize:'16px',fontWeight:600}}/>
      {resource === 'payment_requests' ? 'Nuevo Cobro' : 'Crear'}
    </Button>
  );
};

export default CreateButton;