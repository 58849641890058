import { Box, Button, Popover } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDataProvider, useListContext, useResourceContext } from 'react-admin';
import styles from './FilterList.module.css';
import NewFilter, { DateInput } from './NewFilter';
import { COLORS } from '../../assets/constants/COLORS';
import { useCurrencies } from '../../utils/helpers/useCurrencies';


const estadoOptions =  [ 
  {key: 'approved',  label: 'Aprobados'},
  {key: 'pending', label: 'Pendiente'},
  {key: 'refunded', label: 'Devolución'},
];

const rolesOptions = [
  {key: 'superadmin', label: 'Superadmin'},
  {key: 'admin', label: 'Admin'},
  {key: 'operator', label: 'Operador'}
];

const FilterList = (props) => {
  const {
    showFilters = true,
    status,
    coins,
    roles,
    comercios,
    dates
  } = props;
  const dataProvider = useDataProvider();
  const { filterValues, setFilters } = useListContext();
  const {currencies} = useCurrencies();
  const [showFilter, setShowFilter] = useState([]);
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    try {
      dataProvider.getMerchants().then(({ data }) => {
        setMerchants(data.results);
      });
    } catch {
      console.log('errors merchants');
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'flex-start',
        height: showFilters ? 'auto' : '0px',
        padding:showFilters ? '20px' : '0px',
        flexWrap: 'wrap',
        visibility: showFilters ? 'visible' : 'hidden',
      }}
    >
      {status && (

        <div key={'estado-filter'}>
          <NewFilter
            label={'Estado'}
            source={'status'}
            options={estadoOptions}
            optionValue="key"
          
            optionLabel="label"
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            setFilters={setFilters}
            filterValues={filterValues}
          />
        </div>
      )}
      {coins && (

        <div key={'coins-filter'}>
          <NewFilter
            label={'Moneda'}
            options={currencies.reduce((a, b) => !a?.find(it => it.ticker === b.ticker) ? [...a, b] : a,[])}
            noObj={false}
            source={'coin'}
            optionValue="ticker"
            optionLabel="ticker"
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            setFilters={setFilters}
            filterValues={filterValues}
          />
        </div>
      )}
     
      {roles && (
        <div key={'roles-filter'}>
          <NewFilter
            label={'Roles'}
            options={rolesOptions}
            source={'role'}
            singleSelect
            optionValue="key"
            optionLabel="label"
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            setFilters={setFilters}
            filterValues={filterValues}
          />
        </div>
      )}
       
      {comercios && (
        <div key={'comercio-filter'}>
          <NewFilter
            label={'Comercio'}
            options={merchants}
            source={'merchant_id'}
            optionValue="id"
            optionLabel="name"
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            singleSelect
            setFilters={setFilters}
            filterValues={filterValues}
          />
        </div>
      )}
      {dates && (
        <div key={'dates-filter'}>
          <NewFilter
            label={'Fecha'}
            date
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            setFilters={setFilters}
            filterValues={filterValues}
          />
        </div>
      )}
      {
        [comercios, dates, roles, coins, status].some(Boolean) && (
          <div key={'erase-filter'}>
            <NewFilter
              label={'Borrar filtros'}
              erase
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              setFilters={setFilters}
              filterValues={filterValues}
            />
          </div>
        )
      }
      <div>
      </div>
      {/* <AddFilters
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      /> */}
    </Box>
  );
};
export default FilterList;
