import React from 'react';
import Card from '../../Components/Card/Card';
import { ReferenceInput, SelectInput, TextInput, useGetPermissions, usePermissions } from 'react-admin';
import InputLabel from '../../Components/Input/InputLabel';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';


const Form = ({ isEdit }) => {
  const { isLoading, permissions } = usePermissions();
  const roleValue = useWatch({ name: 'role' });

  const rols = [
    permissions === 'superadmin' && { id: 'superadmin', name: 'Superadmin' },
    { id: 'admin', name: 'Admin' },
    { id: 'operator', name: 'Operador' }
  ];

  return (
    <>
      <Card
        title={'Datos del usuario'}
        border
        sx={{ display: 'flex', flexDirection: 'column' }}
        sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
        sxHeader={{backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7'}}
      >
        <InputLabel
          input={<TextInput required source={'name'} label={'Nombre'} />}
        />
        <InputLabel
          input={<TextInput required source={'email'} label={'Email'} helperText={<Typography sx={{marginTop:'3px', fontSize: '14px', lineHeight:'19px', fontWeight:400, color: 'grey' }}>Se usará para ingresar a Simplo</Typography>} />}
          label={''}
        />
      </Card>

      <Card 
        title={'Permisos'} 
        border
        sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
        sxHeader={{backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7'}}
      >
        <InputLabel
          input={
            <SelectInput
              disabled={isLoading}
              source="role"
              label={'Rol'}
              required
              helperText={<Typography sx={{marginTop:'3px', fontSize: '14px', lineHeight:'19px', fontWeight:400, color: 'grey' }}>Rol que tendra el usuario</Typography>}
              choices={rols} />
          }
          label={''}
        />
      </Card>

      {
        roleValue !== 'superadmin' && (
          <Card 
            title={'Negocio'} 
            border
            sxTitle={{ fontSize: '20px',lineHeight:'27px',fontWeight:600,color:'#000000' }} 
            sxHeader={{backgroundColor:'#FDFDFD',border: '1px solid #E7E7E7'}}
          >
            <InputLabel
              input={<ReferenceInput source="merchant_id" perPage={999} reference="merchants">
                <SelectInput
                  required
                  label=''
                  optionText="name"
                />
              </ReferenceInput>}
              label={''}
            />
          </Card>
        )
      }
    </>
  );
};

export default Form;