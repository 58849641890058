import React from 'react';
import { SaveButton as SaveButtonRA } from 'react-admin';

const SaveButton = ({ label = 'Guardar' }) => {
  return (
    <SaveButtonRA
      icon={null}
      label={label}
      color='secondary'
      sx={{
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        backgroundColor: '#A663FE',
        borderRadius: '5px',
        padding: '12px 20px',
        fontStyle: 'normal',
      }}
    />
  );
};

export default SaveButton;
