import { Skeleton, Tooltip, Typography } from '@mui/material';
import React from 'react';

export const TokenFieldList = ({ record, currencies, isMerchantList }) => {
  const getCoinLogo = (coin) => {
    const currency = currencies?.find((currency) => currency.ticker === coin);
    if (currency) {
      if (currency?.logo) {
        return { type: 'logo', img: currency.logo };
      } else {
        return { type: 'name', name: currency.ticker };
      }
    } else {
      return null;
    }
  };
  if (isMerchantList) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '100%',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        {record?.currencies
          ?.filter((currency) => currency?.enabled)
          .map((transaction, index) => {
            const coinLogo = getCoinLogo(transaction?.ticker);
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: index !== 0 ? '-10px' : '0',
                }}
              >
                {coinLogo ? (
                  coinLogo?.type === 'logo' ? (
                    <Tooltip title={transaction?.ticker} arrow>
                      <img
                        src={coinLogo?.img}
                        alt={transaction?.coin}
                        style={{ width: '30px', height: '30px', flexShrink: 0 }}
                      />
                    </Tooltip>
                  ) : (
                    <Typography>{transaction?.coin}</Typography>
                  )
                ) : (
                  <Skeleton variant='circular' width={30} height={30} />
                )}
              </div>
            );
          })}
      </div>
    );
  }
  if (record?.status === 'approved') {
    const filterCorrectPayment = record?.transactions?.filter(
      (transaction) => transaction?.status === 'correct'
    );
    const coinLogo = getCoinLogo(filterCorrectPayment[0]?.coin);
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          overflowX: 'auto',
          width: '100%',
        }}
      >
        {coinLogo ? (
          coinLogo?.type === 'logo' ? (
            <Tooltip title={filterCorrectPayment[0]?.coin} arrow>
              <img
                src={coinLogo?.img}
                alt={filterCorrectPayment[0]?.coin}
                style={{ width: '30px', height: '30px', flexShrink: 0 }}
              />
            </Tooltip>
          ) : (
            <Typography>{filterCorrectPayment[0]?.coin}</Typography>
          )
        ) : (
          <Skeleton variant='circular' width={30} height={30} />
        )}
      </div>
    );
  } else if (
    record?.transactions?.length >= 1 &&
    record?.status === 'pending'
  ) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '100%',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        {record?.transactions?.map((transaction, index) => {
          const coinLogo = getCoinLogo(transaction?.coin);
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: index !== 0 ? '-10px' : '0',
              }}
            >
              {coinLogo ? (
                coinLogo?.type === 'logo' ? (
                  <Tooltip title={transaction?.coin} arrow>
                    <img
                      src={coinLogo?.img}
                      alt={transaction?.coin}
                      style={{ width: '30px', height: '30px', flexShrink: 0 }}
                    />
                  </Tooltip>
                ) : (
                  <Typography>{transaction?.coin}</Typography>
                )
              ) : (
                <Skeleton variant='circular' width={30} height={30} />
              )}
            </div>
          );
        })}
      </div>
    );
  }
};
