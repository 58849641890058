import React from 'react';
import styles from './TextField.module.css';

const TextField = ({ value, onChange, placeholder, icon, clearIcon, style}) => {
  return (
    <div className={styles.textFieldContainer}>
      {icon &&
      <div className={styles.textFieldIcon}>
        {icon}
      </div>
      }
      <input
        type="text"
        className={styles.textFieldInput}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
      />
      <div className={styles.textFieldClearIcon}>
        {clearIcon && value.length > 0 && <>{clearIcon}</>}
      </div>
    </div>
  );
};

export default TextField;
