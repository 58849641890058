import React, { useEffect, useRef, useState } from 'react';
import Card from '../Card/Card';
import {
  TextInput,
  useDataProvider,
  useInput,
  useRecordContext,
} from 'react-admin';
import CardTokenCheck from '../Input/CardTokenCheck';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { checkAddress } from '../../utils/helpers';
import { useFormContext } from 'react-hook-form';
import styles from './index.module.css';


const PaymentOptions = ({ isCreate, ...props }) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const [currencies, setCurrencies] = useState(record?.currencies);
  const [selectedCoins, setSelectedCoins] = useState([]);

  useEffect(() => {
    if (isCreate) {
      dataProvider
        .getCurrencies({
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'rank', order: '' },
        })
        .then(({ data }) => {
          const currenciesFormat = data?.results?.map((r, index) => ({
            ticker: r.ticker,
            address_needed: r.address_needed,
            currency_logo: r.logo,
            chain_name: r.chain.name,
            chain_logo: r.chain.logo,
            decimals: r.decimals,
            chain_id: r.chain.id,
            enabled: false,
            index: index,
          }));
          setCurrencies(currenciesFormat);
        });
    } else {
      //Primero se ordena las currencies del record, teniendo en cuenta la key ENABLED
      const currenciesFormat = record?.currencies.sort((a, b) => {
        if (a.enabled && !b.enabled) {
          return -1;
        } else if (!a.enabled && b.enabled) {
          return 1;
        } else {
          return 0;
        }
      });
      setCurrencies(
        currenciesFormat?.map((c, index) => ({
          ...c,
          index: index,
        }))
      );
    }
  }, []);
  return (
    <>
      <Card
        title={'Metodos de Pago: Coins'}
        border
        sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}
        sxTitle={{
          fontSize: '20px',
          lineHeight: '27px',
          fontWeight: 600,
          color: '#000000',
        }}
        sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
      >
        {currencies
          ?.filter((token) => token.enabled === true)
          .map((token, key) => (
            <CardTokenCheck
              source={`currencies.[${token.index}]`}
              token={token}
              key={key}
              index={key}
              selectedCoins={selectedCoins}
              setSelectedCoins={setSelectedCoins}
              setCurrencies={setCurrencies}
              currencies={currencies}
            />
          ))}
        <AddCoins currencies={currencies} setCurrencies={setCurrencies} />
      </Card>
      <Card
        title={`Metodos de Pago ${
          selectedCoins?.length >= 1 ? `(${selectedCoins[0].ticker})` : ''
        }: Chains `}
        border
        sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}
        sxTitle={{
          fontSize: '20px',
          lineHeight: '27px',
          fontWeight: 600,
          color: '#000000',
        }}
        sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
      >
        {selectedCoins?.map((chain, key) => (
          <ChainsToken
            source={`currencies.[${chain.index}]`}
            chain={chain}
            key={key}
            index={key}
          />
        ))}
      </Card>
    </>
  );
};
export const AddCoins = ({ currencies, setCurrencies }) => {
  const refBt = useRef(null);
  const [open, setOpen] = useState(false);
  const {
    field: { value, onChange },
  } = useInput({ source: 'currencies' });

  const handleEnabledToken = (token) => {
    const updatedCurrencies = currencies?.map((c, index) => {
      if (c.ticker === token?.ticker && c.chain_id === token?.chain_id) {
        return {
          ...c,
          enabled: !c.enabled,
        };
      }
      return c;
    });
    setCurrencies(updatedCurrencies);
    onChange(updatedCurrencies);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        ref={refBt}
        variant='contained'
        sx={{
          backgroundColor: '#A663FE',
          borderRadius: '10px',
          textTransform: 'none',
          fontSize: '15px',
          lineHeight: '22px',
          fontWeight: 600,
          height: '50px',
          '&:hover': {
            backgroundColor: '#B37BFF',
          },
        }}
      >
        + Agregar Moneda
      </Button>
      <Popover
        anchorEl={refBt.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            maxHeight: '250px',
            minWidth: '150px',
          },
        }}
      >
        {currencies?.map((token, index) => (
          <Box
            key={token?.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
              backgroundColor: token.enabled
                ? 'rgba(132, 72, 160, 0.1)'
                : '#fff',
              borderBottom: '1px solid #ddd',
              '&:last-child': {
                borderBottom: 'none',
              },
            }}
          >
            <Checkbox
              checked={!!token?.enabled}
              onChange={() => handleEnabledToken(token)}
              color='primary'
            />
            <Typography variant='body1' sx={{ marginLeft: '8px' }}>
              {token.ticker}
            </Typography>
          </Box>
        ))}
      </Popover>
    </>
  );
};
export const ChainsToken = ({ chain, index, source }) => {
  const { getValues, setValue } = useFormContext();
  const [address, setAddress] = useState(
    getValues(`currencies.[${chain.index}].address`)
  );
  useEffect(() => {
    setAddress(getValues(`currencies.[${chain.index}].address`));
  }, [index, source, getValues]);

  const refBt = useRef(null);
  const [open, setOpen] = useState(false);
  const {
    field: { value, onChange },
  } = useInput({ source });

  const handleSave = () => {
    const addressValid = checkAddress(address);
    if (!address) {
      onChange({ ...chain, address: null, enabled: false });
      setOpen(false);
      return;
    }

    if (address && !addressValid) {
      return;
    }

    onChange({ ...chain, ...value, address, enabled: true });
    setOpen(false);
  };
  const closePopover = () => {
    setOpen(false);
    handleSave();
  };
  return (
    <div className={styles['container']} ref={refBt}>
      <div className={styles['header']}>
        <img
          className={styles['img-token-check']}
          src={chain.chain_logo}
          alt={chain.chain_name}
        />
        <p className={styles['title-token-check']}>{chain.chain_name}</p>
        <IconButton sx={{ fontSize: '12px' }}>X</IconButton>
      </div>
      <div className={styles['body-chain']}>
        <p>{address}</p>
        <IconButton onClick={() => setOpen(true)}>
          <EditRoundedIcon sx={{ color: '#A663FE', fontSize: '16px' }} />
        </IconButton>
      </div>
      <Popover
        anchorEl={refBt.current}
        open={open}
        onClose={() => closePopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            maxHeight: '250px',
          },
        }}
      >
        <TextInput
          fullWidth
          source={`currencies.[${chain.index}].address`}
          label={'Dirección'}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Button onClick={handleSave}>Guardar</Button>
      </Popover>
    </div>
  );
};
export default PaymentOptions;
