import React, { useRef, useEffect } from 'react';
import { createQR } from '../../utils/helpers';

const QRCodeComponent = ({ url, size = 4 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (url) {
      const qrcode = createQR(url);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const tileSize = size; // Tamaño de cada "tile" o módulo del QR en el canvas
      canvas.height = qrcode.getModuleCount() * tileSize;
      canvas.width = qrcode.getModuleCount() * tileSize;

      for (let row = 0; row < qrcode.getModuleCount(); row++) {
        for (let col = 0; col < qrcode.getModuleCount(); col++) {
          ctx.fillStyle = qrcode.isDark(row, col) ? '#000' : '#fff';
          ctx.fillRect(col * tileSize, row * tileSize, tileSize, tileSize);
        }
      }
    }
  }, [url, size]);

  return (
    <canvas ref={canvasRef} style={{ width: '300px', height: '300px' }}></canvas>
  );
};

export default QRCodeComponent;
