
import { memo } from 'react';
import { TextInput } from 'react-admin';


const InputText = (props) => {
  return <TextInput 
    sx={{
      height: '50px'
    }} 
    label=""
    {...props} />;
};

export default memo(InputText);