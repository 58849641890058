import { Box, Button, Checkbox, FormControlLabel, IconButton, Modal, Radio, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FormDataConsumer, TextInput, useFormGroupContext, useInput } from 'react-admin';
import styles from './../../Components/PaymentOptions/index.module.css';
import { TbEdit } from 'react-icons/tb';
import InputText from './InputText';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import InputLabel from './InputLabel';

const CardTokenCheck = ({
  source,
  token,
  index,
  setCurrencies,
  currencies,
  noDefault
}) => {

  const {
    field: { value, onChange },
  } = useInput({ source });
  const [boolean, setBoolean] = useState(value?.enabled ? value?.enabled : false);
  const [address, setAddress] = useState(value?.address ? value?.address : null);
  const selectCoin = (e, form) => {
    setBoolean(e.target.checked);
    const addressFunc = (boolean, token, value, form) => {
      if(!noDefault) {
        setAddress(token.address);
        return token.address;
      }
      return !boolean ? null :
        token.address ? token.address :
          value.address ? value.address :
            !value.address && boolean ? form['default_evm_address'] : 
              null;
    };
    const updatedCurrencies = currencies?.map((c) =>
      c?.ticker === token?.ticker && c?.chain_name === token?.chain_name
        ? {
          ...c,
          enabled: e.target.checked,
          address: addressFunc(e.target.checked, token, c, form)
        }
        : c
    );
    setCurrencies && setCurrencies(updatedCurrencies);
   
    let tokenValue = { 
      ...token, 
      ...value, 
      enabled: e.target.checked, 
      address: addressFunc(e.target.checked, token, value, form)

    };
    setAddress(tokenValue.address);
    onChange(tokenValue);
    

  };
 
  return (
    <>
      <div
        className={`${styles['container-token-check']} ${styles['card-token-grid']}`}
      >
        <img
          className={styles['img-token-check']}
          src={token.currency_logo}
          alt={token.ticker}
        />
        <p className={styles['title-token-check']}>{token.ticker}</p>
        <p className={styles['subtitle-token-check']}>{token.chain_name}</p>
        {token.enabled ? (
          <div
          //  ref={ref} 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              position: 'relative'
            }}>

            <InputText 
              source={`${source}.address`}
              FormHelperTextProps={{ sx: { display:'none'}}}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              inputProps={{
                dir: 'rtl',
                sx: {
                  fontWeight: '700',
                  fontSize: '12px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  height: '15px',
                  minWidth: '200px'
                }}}

              sx={{
                borderRadius: '3px',
                fontWeight: '700',
                width: 'inherit',
                marginBottom: '0px',
                // maxWidth: '150px',
                minWidth: '200px'

              }}/>
           
          </div>
        ) : (
          <div style={{ height: '15px', width: '150px', }} />
        )}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
                    
            <FormControlLabel control={<Checkbox
              checked={boolean}
              sx={{ fontSize: '12px' }}
              onClick={(e) => selectCoin(e, formData)}
            />}
            />
          }
        </FormDataConsumer>
      </div>
    </>
  );
};


export default CardTokenCheck;
