import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import List from './List';
import Create from './Create';
import Edit from './Edit';

export default {
  list: List,
  icon: PersonOutlineOutlinedIcon,
  create: Create,
  edit: Edit
};