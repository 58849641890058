import React from 'react';
import { CreateBase, useNotify, useRedirect , useResourceContext} from 'react-admin';
import SimpleFormCustom from './SimpleFormCustom';
import CreateHeader from './CreateHeader';

const CreateWrapper = ({ children, ...rest }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const onError = (error) => {
    console.log('error on error', error, { ...error });
    notify(error.body.detail, { type: 'error' });
    // notify(`Could not create post: ${error.message}`);
  };
  const goBack = () => {
    redirect(`/${resource}`);
  };

  return (
    <CreateBase mutationOptions={{ onError }}>
      <SimpleFormCustom header={<CreateHeader goBack={goBack} label='Nuevo Usuario'/>}>
        {children}
      </SimpleFormCustom>
    </CreateBase>
  );
};

export default CreateWrapper;