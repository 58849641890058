import 'react-calendar/dist/Calendar.css';
import React, { useEffect, useRef, useState } from 'react';
import CreateHeader from '../../Components/Form/CreateHeader';
import styles from './Dashboard.module.css';
import Card from '../../Components/Card/Card';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Calendar from 'react-calendar';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { useDataProvider, usePermissions } from 'react-admin';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const optionsButtonsFilter = [
  { name: 'Presets', value: 'presets' },
  { name: 'Days', value: 'days' },
  { name: 'Months', value: 'months' },
  { name: 'Years', value: 'years' },
];
const formatDate = (date, days) => {
  let newDate = new Date();
  newDate.setDate(date.getDate() + days);
  return newDate;
};
const presets = [
  {
    text: 'Ultimos 7 días',
    start: new Date(),
    end: formatDate(new Date(), 7),
  },
  {
    text: 'Ultimos 30 días',
    start: new Date(),
    end: formatDate(new Date(), 30),
  },
  {
    text: 'Ultimos 90 días',
    start: new Date(),
    end: formatDate(new Date(), 90),
  },
];
const Dashboard = () => {
  const { permissions } = usePermissions();
  const btRef = useRef(null);
  //Filter states
  const [calendarFormat, setCalendarFormat] = useState('presets');
  const [filterDays, setFilterDays] = useState(undefined);
  const [valueCalendar, setValueCalendar] = useState([]);
  const [open, setOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [monthValues, setMonthValues] = useState(undefined);
  const [isChecked, setIsChecked] = useState(presets.map((p) => false));
  //Chart states
  const [merchantList, setMerchantList] = useState([]);
  const [visibleAmount, setVisibleAmount] = useState(true);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedMoneyType, setSelectedMoneyType] = useState('$');
  const [merchantData, setMerchantData] = useState([]);
  const dataProvider = useDataProvider();

  const handlerChangeCurrencie = (e) => {
    const findMerchant = dashboardData?.filter((m) => m.merchant === e);
    if (findMerchant.length > 0) {
      setSelectedMerchant(e);
      setMerchantData(...findMerchant);
    }
  };
  const handlerChangeMoneyType = (e) => {
    setSelectedMoneyType(e);
  };
  const handlerCalendarFormat = (e, data) => {
    setCalendarFormat(data);
  };
  const handlerCallApi = (e, start, end, index) => {
    if (typeof e === 'object' && e.length === 2) {
      const startToShow = new Date(e[0]).toLocaleString('es-ES', {
        month: 'short',
        day: 'numeric',
      });
      const endToShow = new Date(e[1]).toLocaleString('es-ES', {
        month: 'short',
        day: 'numeric',
      });
      setValueCalendar(e);
      setFilterDays({
        start: startToShow,
        end: endToShow,
      });
      let startForFetch = new Date(e[0]);
      startForFetch.setHours(0, 0, 0, 0);
      let endForFetch = new Date(e[1]);
      endForFetch.setHours(23, 59, 59, 999);
      dataProvider
        .getStatistics({
          filter: {
            from_date: startForFetch,
            to_date: endForFetch,
          },
        })
        .then(({ data }) => {
          if (data) {
            setDashboardData(data.results);
            setMerchantList(data?.results?.map((m) => m.merchant));
          }
        });
      setSelectedMerchant('');
    } else {
      setIsChecked((isChecked) => {
        return isChecked.map((c, i) => {
          if (c === true) return false;
          if (i === index) return e;
          return c;
        });
      });
      const startToShow = new Date(start).toLocaleString('es-ES', {
        month: 'short',
        day: 'numeric',
      });
      const endToShow = new Date(end).toLocaleString('es-ES', {
        month: 'short',
        day: 'numeric',
      });
      setFilterDays({ start: startToShow, end: endToShow });

      let startForFetch = new Date(start);
      startForFetch.setHours(0, 0, 0, 0);
      let endForFetch = new Date(end);
      endForFetch.setHours(23, 59, 59, 999);
      dataProvider
        .getStatistics({
          filter: {
            from_date: startForFetch,
            to_date: endForFetch,
          },
        })
        .then(({ data }) => {
          if (data) {
            setDashboardData(data.results);
            setMerchantList(data?.results?.map((m) => m.merchant));
          }
        });
      setSelectedMerchant('');
    }
  };
  const handleMonthClick = (value) => {
    const selectedMonth = new Date(value).toLocaleString('es-ES', {
      month: 'short',
      day: 'numeric',
    });
    if (!filterDays) {
      setValueCalendar([value]);
      setFilterDays({ start: selectedMonth, end: null });
      setMonthValues({ start: value, end: null });
    } else if (filterDays.start && !filterDays.end) {
      setMonthValues({ ...monthValues, end: value });
      setFilterDays({ start: filterDays.start, end: selectedMonth });
      setValueCalendar([filterDays.start, selectedMonth]);
      setValueCalendar([...valueCalendar, value]);
      let startForFetch = new Date(monthValues.start);
      startForFetch.setHours(0, 0, 0, 0);
      let endForFetch = new Date(value);
      endForFetch.setHours(23, 59, 59, 999);
      dataProvider
        .getStatistics({
          filter: {
            from_date: startForFetch,
            to_date: endForFetch,
          },
        })
        .then(({ data }) => {
          if (data) {
            setDashboardData(data.results);
            setMerchantList(data?.results?.map((m) => m.merchant));
          }
        });
      setSelectedMerchant('');
    } else {
      setValueCalendar([value]);
      setFilterDays({ start: selectedMonth, end: null });
      setMonthValues({ start: value, end: null });
    }
  };
  const handleYearClick = (value) => {
    const selectedMonth = new Date(value).getFullYear();
    if (!filterDays) {
      setValueCalendar([value]);
      setFilterDays({ start: selectedMonth, end: null });
      setMonthValues({ start: value, end: null });
    } else if (filterDays.start && !filterDays.end) {
      setMonthValues({ ...monthValues, end: value });
      setFilterDays({ start: filterDays.start, end: selectedMonth });
      setValueCalendar([filterDays.start, selectedMonth]);
      setValueCalendar([...valueCalendar, value]);

      let startForFetch = new Date(monthValues.start);
      startForFetch.setHours(0, 0, 0, 0);
      let endForFetch = new Date(value);
      endForFetch.setHours(23, 59, 59, 999);
      dataProvider
        .getStatistics({
          filter: {
            from_date: startForFetch,
            to_date: endForFetch,
          },
        })
        .then(({ data }) => {
          if (data) {
            setDashboardData(data.results);
            setMerchantList(data?.results?.map((m) => m.merchant));
          }
        });
      setSelectedMerchant('');
    } else {
      setValueCalendar([value]);
      setFilterDays({ start: selectedMonth, end: null });
      setMonthValues({ start: value, end: null });
    }
  };
  useEffect(() => {
    dataProvider.getStatistics().then(({ data }) => {
      if (data) {
        setDashboardData(data.results);
        setMerchantList(data?.results?.map((m) => m.merchant));
      }
    });
  }, []);
  useEffect(() => {
    if (permissions === 'admin') {
      const findMerchant = dashboardData?.filter(
        (m) => m.merchant === merchantList[0]
      );
      if (findMerchant.length > 0) {
        setSelectedMerchant(merchantList[0]);
        setMerchantData(...findMerchant);
      }
    }
  }, [dashboardData]);

  //Set para evitar repetir los dias en el array
  const labels = [...new Set(merchantData?.days?.map((f) => f.day))];
  const datasets = [];

  // Por cada dia del array de dias :
  merchantData?.days?.forEach((transaction) => {
    // Buscamos si ya existe un dataset para esa moneda
    let existingDataset = datasets?.find(
      (dtset) => dtset.label === transaction.coin
    );

    if (existingDataset) {
      // Si ya existe un dataset para la moneda, sumamos el valor al día correspondiente
      const dayIndex = labels.indexOf(transaction.day);
      existingDataset.data[dayIndex] += transaction.total_ars;
    } else {
      // Si no existe, creamos un nuevo dataset para esa moneda
      const newDataset = {
        label: transaction.coin,
        data: Array(labels.length).fill(0),
        backgroundColor:
          '#' + Math.floor(Math.random() * 16777215).toString(16),
        stack: 'stack1',
      };
      datasets.push(newDataset);

      // Añadimos el valor del día actual
      const dayIndex = labels.indexOf(transaction.day);
      newDataset.data[dayIndex] = transaction.total_ars;
    }
  });

  const data = {
    labels,
    datasets,
  };
  const options = {
    type: 'bar',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        display: false,
        // stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };
  const pickCalendarFormat = () => {
    if (calendarFormat === 'presets') {
      return (
        <div className={styles.presets}>
          {presets?.map((preset, index) => {
            return (
              <div className={styles.presetElement} key={preset.text}>
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    checked={isChecked[index]}
                    onChange={(e) =>
                      handlerCallApi(
                        e.target.checked,
                        preset.start,
                        preset.end,
                        index
                      )
                    }
                  />
                  <Typography>{preset.text}</Typography>
                </div>
                <Typography variant='subtitle2'>
                  {preset?.start?.toLocaleDateString('es-ES', {
                    month: 'short',
                    day: 'numeric',
                  })}
                  -
                  {preset?.end?.toLocaleDateString('es-ES', {
                    month: 'short',
                    day: 'numeric',
                  })}
                </Typography>
              </div>
            );
          })}
        </div>
      );
    } else if (calendarFormat === 'days') {
      return (
        <Calendar
          view='month'
          selectRange={true}
          returnValue='range'
          onChange={handlerCallApi}
          value={valueCalendar}
        />
      );
    } else if (calendarFormat === 'months') {
      return (
        <Calendar
          view='year'
          selectRange={true}
          returnValue='range'
          value={valueCalendar}
          onClickMonth={handleMonthClick}
        />
      );
    } else if (calendarFormat === 'years') {
      return (
        <Calendar
          view='decade'
          selectRange={true}
          returnValue='range'
          value={valueCalendar}
          onClickYear={handleYearClick}
        />
      );
    } else {
      return;
    }
  };

  return (
    <div className={styles.container}>
      <CreateHeader label='Dashboard' backAction={false} onlyEditText={true} />
      <div className={styles.bodyContainer}>
        <div className={styles.filterDaysContainer}>
          <div
            className={styles.filter}
            ref={btRef}
            onClick={() => setOpen(true)}
          >
            {filterDays !== undefined ? (
              <p>
                {filterDays?.start} - {filterDays?.end}
              </p>
            ) : (
              <p>Filtro por día</p>
            )}
          </div>
          <Popover
            anchorEl={btRef.current}
            onClose={() => setOpen(false)}
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            sx={{
              '& .MuiPopover-paper': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                marginTop: '10px',
                maxWidth: '350px',
              },
            }}
          >
            <ButtonGroup
              disableElevation
              variant='contained'
              sx={{ height: '35px' }}
            >
              {optionsButtonsFilter?.map((button) => {
                return (
                  <Button
                    key={button.name}
                    onClick={(e) => handlerCalendarFormat(e, button.value)}
                    sx={{
                      width: '80px',
                      backgroundColor:
                        calendarFormat === button.value ? '#8502C2' : '#E7E7E7',
                      color:
                        calendarFormat === button.value ? '#FFFFFF' : '#000000',
                      textTransform: 'none',
                      '&:hover': {
                        color: '#fff',
                      },
                    }}
                  >
                    {button?.name}
                  </Button>
                );
              })}
            </ButtonGroup>
            {pickCalendarFormat()}
          </Popover>
        </div>
        <Card
          title={'Total procesado'}
          sx={{
            display: 'flex',
            gap: 3,
            flexWrap: 'wrap',
            padding: '15px 18px',
          }}
          sxTitle={{
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 600,
            color: '#000000',
          }}
          sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
        >
          <div className={styles.moneyContainer}>
            <p className={styles.totalAmount}>
              {visibleAmount ? '$54.500.000' : '$********'}
            </p>
            {visibleAmount ? (
              <IconButton onClick={() => setVisibleAmount(!visibleAmount)}>
                <VisibilityOutlinedIcon
                  sx={{ cursor: 'pointer', fontSize: '25px' }}
                />
              </IconButton>
            ) : (
              <IconButton onClick={() => setVisibleAmount(!visibleAmount)}>
                <VisibilityOffOutlinedIcon
                  sx={{ cursor: 'pointer', fontSize: '25px' }}
                />
              </IconButton>
            )}
          </div>
        </Card>
        <div className={styles.coinsProcessed}>
          <div className={styles.coinsProcessedHeader}>
            <div className={styles.coinsProcessedHeaderTitleSelect}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '27px',
                  fontWeight: 600,
                  color: '#000000',
                }}
              >
                Cantidad procesada por comercio
              </Typography>
              {merchantList?.length > 0 ? (
                <FormControl sx={{ minWidth: '150px' }}>
                  <InputLabel id='select-coin'>Comercio</InputLabel>
                  <Select
                    labelId='select-coin'
                    id='select'
                    value={selectedMerchant}
                    onChange={(e) => handlerChangeCurrencie(e.target.value)}
                    size='small'
                  >
                    {merchantList?.map((merchant) => {
                      return (
                        <MenuItem value={merchant} key={merchant}>
                          {merchant}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <Skeleton width={150} height={30} />
              )}
            </div>
            <ButtonGroup
              disableElevation
              variant='contained'
              sx={{ height: '35px' }}
            >
              <Button
                sx={{
                  width: '80px',
                  backgroundColor:
                    selectedMoneyType === '$' ? '#8502C2' : '#E7E7E7',
                  color: selectedMoneyType === '$' ? '#FFFFFF' : '#000000',
                  '&:hover': {
                    color: '#FFFFFF',
                  },
                }}
                onClick={() => handlerChangeMoneyType('$')}
              >
                $
              </Button>
              <Button
                sx={{
                  width: '80px',
                  backgroundColor:
                    selectedMoneyType === '%' ? '#8502C2' : '#E7E7E7',
                  color: selectedMoneyType === '%' ? '#FFFFFF' : '#000000',
                  '&:hover': {
                    color: '#FFFFFF',
                  },
                }}
                onClick={() => handlerChangeMoneyType('%')}
                disabled
              >
                %
              </Button>
            </ButtonGroup>
          </div>
          <div className={styles.graphic}>
            {merchantList?.length > 0 ? (
              <>
                {selectedMerchant ? (
                  <Bar data={data} options={options} />
                ) : (
                  <>
                    {permissions === 'superadmin' && (
                      <Typography>
                        Selecciona un comercio para mostrar el grafico.
                      </Typography>
                    )}
                  </>
                )}
              </>
            ) : (
              <Typography>
                No hay datos relevantes en la fecha seleccionada.
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
