import { Autocomplete, Box, Button, Divider, IconButton, Skeleton, TextField, Typography } from '@mui/material';

import { useCallback, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TbCalendar } from 'react-icons/tb';
import Card from '../../../Components/Card/Card';
import { useDataProvider, usePermissions } from 'react-admin';
import { useQuery } from 'react-query';
import { theme } from '../../../Theme';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export const DashbordFilters = (props) => {

  const {
    filters,
    setFilters
  } = props;
  const[date, setDate] = useState({
    'from_date': filters?.from_date,
    'to_date': filters?.to_date
  });
  const [merchant, setMerchant] = useState(null);
  const { permissions } = usePermissions();

  const dataProvider = useDataProvider();
  const { refetch, data } = useQuery(['merchants'],() => dataProvider.getMerchants(), {
    enabled: permissions === 'superadmin'
  } );
  const handleDate = (e, key) => {
    const dateHandler = (prev) => ({
      ...prev,
      [key]:e
    });
    setFilters(dateHandler);
    setDate(dateHandler);
  };
  const handleAutocomplete = (e,v) => {
    setMerchant(v);
    setFilters(prev => ({...prev, merchant_id: [v.id]}));
  };
  
  return (
    <Card 
      collapse 
      title="Filtros"
      border sx={{display: 'flex', flexDirection: 'row', width: 'auto', gap: '20px', alignItems: 'center', justifyContent: 'flex-start'}}>
      <FilterComponents title="Periodo" type="dateRange" onChange={handleDate} value={date} />
      <Divider orientation='vertical' sx={{height: '100px', border: '1px solid #d0d0d0'}} />
      {permissions === 'superadmin' && data && <FilterComponents title="Comercios" type="autocomplete" options={data?.data?.results}  value={merchant} onChange={handleAutocomplete} />}
    </Card>
  );
};
  
  
export const FilterComponents = (props) => {
  
  const {
    type,
    onChange,
    format,
    value,
    currentRef
  } = props;
  
  const ModalBox = ({title, children}) => (
    <Box sx={{
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      <Typography sx={{textTransform: 'uppercase', fontSize: '.8em', fontWeight: '700', color: theme.palette.primary.main}}>{title}</Typography>
      <Box ref={currentRef} sx={{
        padding: 1,
        display: 'flex',
        flexDirection: 'row',
        gap:1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        // maxWidth: '300px',
        height: 'auto'
      }}>
        {children}
      </Box>
    </Box>
  );
  
  const formatter = useCallback((e) => dayjs(e).format(format ? format : 'YYYY-MM-DD'), [format]);
  switch(!!type) {
  case type === 'dateRange':
    return (
      <ModalBox title="Periodo">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography sx={{fontSize: '.9em', fontWeight: '600', color: theme.palette.primary.main}}>Desde</Typography>
          <DatePicker sx={{width: '200px'}} ref={currentRef} value={dayjs(value['from_date'])} onChange={(e) => onChange(formatter(e), 'from_date')} />
          <Typography sx={{fontSize: '.9em', fontWeight: '600', color: theme.palette.primary.main}}>Hasta</Typography>
          <DatePicker sx={{width: '200px'}} ref={currentRef} value={dayjs(value['to_date'])} onChange={(e) => onChange(formatter(e), 'to_date')} />
        </LocalizationProvider>
      </ModalBox>
    );
  case type === 'autocomplete':
    return (
      <ModalBox title="Comercios">
        <Autocomplete sx={{width: '100%', minWidth:'200px'}} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField variant="outlined" {...params} />} {...props} />
      </ModalBox>
    );
  }
};