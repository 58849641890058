import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { createQR } from '../../../utils/helpers';
import { Box, IconButton, Modal } from '@mui/material';
import Button from '../../../Components/Button/Button';
import QRCodeComponent from '../../../Components/QRCode/QRCodeComponent';
import DownloadIcon from '@mui/icons-material/Download';
import Card from '../../../Components/Card/Card';



export const QrCodeCard = () => {
  const record = useRecordContext();
  const [openModal, setOpenModal] = useState(false);
  
  const urlEnv =
      process.env.REACT_APP_DEVELOPMENT === 'true'
        ? 'https://develop.pagar.simplefi.tech'
        : 'https://pagar.simplefi.tech';
  const urlQRCode = `${urlEnv}/${record?.slug}/?static=true`;
  
  const handleClickDownload = () => {
    const qrcode = createQR(urlQRCode);
    var url = qrcode.createDataURL(8, 1);
    var link = document.createElement('a');
    link.download = `${record.name}-QR.png`;
    link.href = url;
    link.width = '300px';
    link.height = '300px';
    link.click();
  };
  
  if (record) {
    return (
      <Card
        title={'QR'}
        sxHeader={{
          backgroundColor: '#FDFDFD',
          border: '1px solid #E7E7E7',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3 }}>
          <IconButton
            aria-label='delete'
            variant={'contained'}
            onClick={handleClickDownload}
          >
            <DownloadIcon />
          </IconButton>
          <Button
            size='small'
            variant='outlined'
            onClick={() => setOpenModal(true)}
          >
              Ver QR
          </Button>
        </Box>
        <Modal open={openModal} handleClose={() => setOpenModal(false)}>
          <QRCodeComponent url={urlQRCode} />
        </Modal>
      </Card>
    );
  }
};
  