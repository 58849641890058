
import Card from '../../Components/Card/Card';
import { ChartLineComponent } from './Components/ChartLineComponent';
import { ChartPieComponent } from './Components/ChartPieComponent';
import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { DashbordFilters } from './Components/DashboardFilters';
import { Box, Typography } from '@mui/material';


export const DashboardList = () => {
  const [filters, setFilters] = useState({
    'from_date': dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    'to_date': dayjs().format('YYYY-MM-DD')
  });

  return (
    <Box key="dashboard-Box" sx={{
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'auto'
    }}>
      <Box mt={2} sx={{width: 'auto', marginX: '100px', gap: '10px', display: 'flex', flexDirection: 'column'}} title="Dashboard">
        <Typography sx={{
          fontSize: '2.1em',
          fontWeight: '700',
          color: 'primary',
          alignSelf: 'flex-start'
        }}>Dashboard</Typography>
        <DashbordFilters filters={filters} setFilters={setFilters} />
        <Card 
          border
          sxHeader={{
            width: 'auto',
          }}
          title="Total ventas"
          sx={{
            gap: '100px',
            display: 'flex',
            flexWrap: 'wrap', 
            height:'auto', 
            flexDirection : 'row',
            justifyContent: 'flex-start', 
            alignItems: 'center', 
            width: 'auto',
            
          }}>
          <ChartLineComponent filters={filters} options={{plugins: {legend: {display: false}}}} />
        </Card>
        <Card sxHeader={{
          width: 'auto', 
          maxWidth: '700px',

        }} sx={{width: 'auto'}} border title="Total por coins">
          <ChartPieComponent filters={filters} />
        </Card>
      </Box>
    </Box>
  );
};



