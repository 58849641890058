import React from 'react';
import { Button as ButtonMUI } from '@mui/material';

const Button = ({ children, ...props }) => {
  return (
    <ButtonMUI
      variant='contained'
      color='secondary'
      size='small'
      {...props}
    >
      {children}
    </ButtonMUI>
  );
};

export default Button;