
import Create from './Create.jsx';
import List from './List.jsx';
import Show from './Show.jsx';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

export default {
  list: List,
  show: Show,
  create: Create,
  icon: PaidOutlinedIcon
};