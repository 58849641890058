
export const parseCurrencies = (arr) => {
  return arr?.map((r, index) => ({
    ticker: r.ticker,
    address_needed: r.address_needed,
    currency_logo: r.logo,
    chain_name: r.chain.name,
    chain_logo: r.chain.logo,
    decimals: r.decimals,
    chain_id: r.chain.id,
    enabled: false,
    index: index,
  }));
};

export const initialCurrencies = (arr) => {
  return arr.sort((a, b) => {
    if (a.enabled && !b.enabled) {
      return -1;
    } else if (!a.enabled && b.enabled) {
      return 1;
    } else {
      return 0;
    }
  }).map((c, index) => ({
    ...c,
    index: index,
  }));
};