
import { defaultLineOptions } from './defaults';
import { createLineDataset, createPieDataset, dayFormatter, labelFormatter, sortedDays, sumTotal } from '../Functions';
import { useGetStatistics } from '../Hooks';
import { Charts } from './Charts';
import { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { theme } from '../../../Theme';


export const ChartLineComponent = (props) => {
  const { 
    filters,
    options = {} 
  } = props;

  const [resultData, setResultData] = useState({});

  const { refetch, isFetching } = useGetStatistics({
    filters: filters,
    onSuccess: ({data: {results}}) => {
      if (results.length > 0) {
        const datasets = createLineDataset('total_ars', results?.[0]?.days, {}, true, true);
        const labels = labelFormatter(sortedDays(results?.[0].days, 'day', true), 'day', dayFormatter);
        const data = {datasets, labels};
        setResultData(data);
      } else {
        setResultData([]);
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [filters]);
  const config = useMemo(() => {
     
    return {
      isLoading: isFetching,
      type: 'line',
      data: resultData,
      options: {...defaultLineOptions,  ...options}
    };
  },[resultData, isFetching]);

  return (
    <Charts {...config} />
  );
};


export const DashboardCard = (props) => {
  const { total, children, showCoins ,data } = props;
  const labels = data?.labels;
  const coins = data?.datasets?.[0]?.data;
  const formatter = new Intl.NumberFormat('en-ES', {
    style: 'currency',
    currency: 'ARS',
  });
  return (
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap:'20px',
      }}>
      {children}
      <Box>

        { total && (
          <>
            <Divider orientation='vertical' />
            <Box sx={{
              height: '100%',
              minWidth: '200px'

            }}>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '0.7em',
                textTransform:'uppercase',
                color: '#a6a6a6'
              }}>Total: </Typography>
              <Typography sx={{
                fontWeight: '600',
                fontSize: '1.3em',
                textTransform:'uppercase',
              }}>{formatter.format(total).replace('ARS', '$')}</Typography>
        
            </Box>
          </>
        )}
        <Divider />
        {
          showCoins && (
            <Box mt={2} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              maxWidth: '150px'
            }}>
              { coins?.map((item, index) => (
                <Box  ml={2} sx={{display:'flex',flexDirection:'row', gap:'5px', justifyContent: 'space-between', width: '100%'}}>
                  <Typography sx={{fontSize: '.7em', textTransform: 'uppercase', fontWeight: '600'}}>{labels[index]}</Typography>
                  <Typography sx={{justifySelf: 'flex-start', fontSize: '.7em', textTransform: 'uppercase', fontWeight: '600'}}>{formatter.format(item).replace ('ARS', '$')}</Typography>
                </Box>
              ))}
            </Box>
          )
        }
      </Box>

    </Box>
  );
};