import React, { memo } from 'react';
import Card from '../../Components/Card/Card';
import InputLabel from '../../Components/Input/InputLabel';
import { TextInput, usePermissions } from 'react-admin';
import PaymentOptions from '../../Components/PaymentOptions/PaymentOptions';
import { MerchantsInfo } from './components/MerchantsInfo';
import { PaymentMethods } from './components/PaymentMethods';
import { CampaignsInfo } from './components/CampaignsInfo';

const Form = ({ isCreate }) => {
  const { permissions } = usePermissions();
  return (
    <>
      <MerchantsInfo />
      {/* <PaymentOptions isCreate={isCreate} /> */}
      {permissions === 'superadmin' && <CampaignsInfo />}
      <PaymentMethods {...isCreate} />
    </>
  );
};


export default memo(Form);

