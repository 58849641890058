import dayjs from 'dayjs';
import QRCode from 'qrcode-generator';
const web3 = require('web3-validator');

export const createQR = (url) => {
  const qrcode = new QRCode(0, 'L');
  qrcode.addData(url);
  qrcode.make();

  return qrcode;
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
};

export const calculateDate = (date) => {
  const d = dayjs(date).subtract(3, 'h');
  return d;
};

export const checkAddress = (address) => {
  return web3.isAddress(address);
};

export const strCapitalize = (str) => {
  return str[0].toUpperCase() + [...str].filter((_, index) => index !== 0).join('');
};