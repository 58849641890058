import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

export const useGetStatistics = ({ enabled = true, filters = {}, onSuccess, onError } = {}) => {
  const dataProvider = useDataProvider();
  const { refetch, isFetching, data } = useQuery(['getStatistics'], () => dataProvider.getStatistics({filter: filters}), {
    onSuccess: onSuccess && onSuccess,
    onError: onError && onError,
    enabled: enabled
  });

  return {
    refetch,
    isFetching,
    data
  };
};