import { NumberInput, TextInput, useInput } from 'react-admin';
import Card from '../../../Components/Card/Card';
import InputLabel from '../../../Components/Input/InputLabel';
import InputText from '../../../Components/Input/InputText';
import { Box, Input, Typography } from '@mui/material';
import { LinkCheckout } from '../../Settings/Components/LinkCheckout';
import { InputWalletHash } from './InputWalletHash';
import { useState } from 'react';
import { strCapitalize } from '../../../utils/helpers';
import { TooltipButton } from './PaymentMethods';
import { BsPercent } from 'react-icons/bs';

export const MerchantsInfo = () => {

  return (
    <Card title={'Datos del negocio'}
      border
      sx={{
        padding: '21px 21px 12px 36px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '20px',
        width: '100%'
      }}>
      <InputLabel
        
        input={<InputText source={'name'} label={''} />}
        label={'Nombre'}
      />
      <InputLabel
        

        input={<InputText source={'notification_url'} label={''} />}
        label={'URL de Notificaciones'}
      />
      <InputLabel
        

        input={<InputText source={'direction'} label={''} />}
        label={'Dirección'}
      />
      <InputLabel
        input={<InputText source={'google_maps_link'} label={''} />}
        

        label={'Google Maps URL'}
      />
      <LinkCheckout/>
      {/* <InputWalletHash sx={{gridColumn: 'span 2'}} source="default_hash" /> */}
    </Card>
  );
};
