


export const defaultLineOptions = {
  responsive: true,
  maintainAspectRatio: true,
   
  layout: {
    padding: 0,
      
  },
  elements: {
    padding: 5,
    margin: 10,
    bar: {
      borderWidth: 7,
      padding: 5,
      margin: 10,
    },
      
    point:{
      pointRadius: 5,
      backgroundColor: '#fff'
    }
  },
  scales: {
    x:  {
      min:0,
      max: 30,
      suggestedMin: 0,
      suggestedMax: 30
    },
    y: {
      // type: 'logarithmic',
      alignToPixels: true,
      min: 0,
      ticks: {
        display: true,
        callback: function(value) {
          return value.toLocaleString('es-ar', {
            style: 'currency',
            maximumFractionDigits: 0,
            currency: 'ARS'
          });
        }
      },
    },
  },
  // plugins: {
  //   datalabels: {
  //     display: false
  //   },
  //   legend: {
  //     display: true,
  //     position: 'bottom',
  //     labels: {
  //       usePointStyle: true,
  //       pointStyle: 'line',
  //       padding: 10,
  //       font: { 
  //         family: 'Arial'
  //       }
  //     }
  //   },
  //   title: {
  //     display: false,
  //   },
  // },
};

export const defaultPieOptions = {
  plugins: {
    legend: {
      position: 'right'
    }
  }
};