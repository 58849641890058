
import { Box } from '@mui/material';
import { Spinner } from '../../../Components/Spinner/Spinner';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
  LogarithmicScale,
  ArcElement,
  RadialLinearScale
} from 'chart.js';
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import { sumTotal } from '../Functions';
import { DashboardCard } from './ChartLineComponent';
ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
  ArcElement,
  LogarithmicScale
);

export const ChartBox = ({ total, children, sx, ...rest }) => (
  <Box sx={{display: 'flex', width: 'auto', height: '400px', ...sx}}>
    <DashboardCard {...rest} total={total}>
      {children}
    </DashboardCard>
  </Box>
);

export const Charts = (props) => {
  const { type, isLoading, data } = props;
  switch(!!type){
  case isLoading || !props.data?.datasets?.[0].data:
    return <Spinner />;
  case data.length === 0 || data?.datasets?.[0].data?.length === 0:
    return <ChartBox >
        No results
    </ChartBox>;
  case type === 'line':
    return <ChartBox {...props} total={sumTotal(props?.data.datasets?.[0].data,)}>
      <Line {...props} />
    </ChartBox>;
  case type === 'pie':
    return <ChartBox {...props} total={sumTotal(props.data?.datasets?.[0].data,)}>
      <Pie {...props} />
    </ChartBox>;
  case type === 'doughnut':
    return <ChartBox {...props} showCoins total={sumTotal(props.data?.datasets?.[0].data,)}>
      <Doughnut {...props} />
    </ChartBox>;
  }

};