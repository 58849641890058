import { Box, Checkbox, IconButton, Popover, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import stylesmodule from './CardToken.module.css';
import {
  MdCheck,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';

export const CardToken = ({
  enabled,
  handleClick,
  token,
  handleOpenModal,
  address,
  showAddress,
  showChain,
}) => {
  return (
    <Box
      sx={{
        ...styles.container,
        backgroundColor: enabled ? 'rgba(132, 72, 160,.1)' : '#FFF',
        border: enabled ? '2px solid #8448A0' : '1px solid #8448A0',
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
          <img src={token.currency_logo} width={24} height={24} />
          <Typography sx={styles.ticker}>{token.ticker}</Typography>
        </Box>
        {token.address_needed && handleOpenModal && (
          <IconButton sx={{ padding: '5px' }} onClick={handleOpenModal}>
            <EditRoundedIcon style={{ fontSize: '16px' }} />
          </IconButton>
        )}
      </Box>
      {showChain && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '3px',
          }}
        >
          <img src={token.chain_logo} width={14} height={14} />
          <Typography sx={styles.tickerChain}>{token.chain_name}</Typography>
        </Box>
      )}
      {showAddress && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography sx={styles.tokenAddress}>{address}</Typography>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  container: {
    width: '180px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    borderRadius: '9px',
    cursor: 'pointer',
    padding: '9px',
  },
  ticker: {
    fontSize: '12px',
    fontWeight: '600',
  },
  tickerChain: {
    fontSize: '9px',
    fontWeight: '600',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    backgroundColor: '#FFF',
    padding: 2,
    borderRadius: '9px',
  },
  tokenAddress: {
    fontSize: '9px',
    color: 'grey',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    direction: 'rtl',
  },
};

export const NewCardToken = ({ enabled, handleClick, token, currency }) => {
  const [open, setOpen] = useState(false);
  const [chainsSelected, setChainsSelected] = useState([]);
  const refSelector = useRef(null);
  const handlerOpen = () => {
    setOpen(!open);
  };
  const handlerSelectChain = (e, chain) => {
    if (chainsSelected.includes(chain)) {
      setChainsSelected(chainsSelected.filter((c) => c !== chain));
    } else {
      setChainsSelected([...chainsSelected, chain]);
      handleClick(currency);
    }
  };
  return (
    <div
      ref={refSelector}
      className={stylesmodule.container}
      style={{
        backgroundColor: chainsSelected.includes(token.chain_name)
          ? '#F0E0F8'
          : '#f8f8f8',
        border: chainsSelected?.includes(token?.chain_name)
          ? '1px solid #8448A0'
          : '1px solid #e4e4e4',
      }}
    >
      <div className={stylesmodule.header} onClick={handlerOpen}>
        <div className={stylesmodule.headerLeft}>
          <img
            src={token.currency_logo}
            alt={token.ticker}
            className={stylesmodule.logo}
          />
          <p className={stylesmodule.ticker}>{token.ticker}</p>
        </div>
        <div className={stylesmodule.headerRight}>
          <div className={stylesmodule.headerSubRight}>
            <p>Ver networks</p>
            {open ? (
              <MdKeyboardArrowUp size={20} onClick={() => setOpen(!open)} />
            ) : (
              <MdKeyboardArrowDown size={20} onClick={() => setOpen(!open)} />
            )}
          </div>
          <div
            className={stylesmodule.circularSelected}
            style={{
              backgroundColor: chainsSelected?.includes(token?.chain_name)
                ? '#8448A0'
                : '#fff',
            }}
          >
            {chainsSelected?.includes(token?.chain_name) && (
              <MdCheck color='#fff' />
            )}
          </div>
        </div>
      </div>
      <div className={stylesmodule.allCoins}></div>
      <Popover
        anchorEl={refSelector.current}
        onClose={() => setOpen(false)}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          '& .MuiPopover-paper': {
            width: '350px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
          },
        }}
      >
        <div className={stylesmodule.tokenChainContainer}>
          <img
            src={token.chain_logo}
            alt={token.ticker}
            className={stylesmodule.logoChain}
          />
          <p className={stylesmodule.tokenChain}>{token.chain_name}</p>
        </div>
        <Checkbox
          onChange={(e) => handlerSelectChain(e.target.value, token.chain_name)}
          checked={chainsSelected.includes(token.chain_name)}
        />
      </Popover>
    </div>
  );
};
