import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';

const getParamsURL = () => {
  let network = null;
  let network_merchant_id = null;

  const splitParams = window.location.href?.split('?');
  const params = splitParams.length > 1 && splitParams[1].split('&');
  network = params && params[0]?.split('=')[1];
  network_merchant_id = params && params[1]?.split('=')[1];
  return ({ network, network_merchant_id });

};

const NetworkLink = () => {
  const [loading, setLoading] = useState(false);
  const { network, network_merchant_id } = getParamsURL();
  const notify = useNotify();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();

  const handleClick = () => {
    setLoading(true);
    dataProvider.post('merchants/connect', { network, network_merchant_id })
      .then((e) => {
        notify('Comercio vinculado con éxito!', { type: 'success' });
        redirect('/payment_requests');
      }).catch(() => {
        notify('No se pudo vincular el comercio', { type: 'error' });
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!network || !network_merchant_id) {
      redirect('/payment_requests');
      notify('La dirección URL no es correcta', { type: 'error' });
    }
  }, [network, network_merchant_id]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>¿Deseas vincular tu comercio a la red <span style={{ fontWeight: '600' }}>{network}</span>?</Typography>
      <Button variant='contained' disabled={loading} onClick={handleClick}>Vincular</Button>
    </Box>
  );
};

export default NetworkLink;