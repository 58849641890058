import dayjs from 'dayjs';



/* ------------------- COLOR FUNCTIONS --------------------------*/


export function generateRandomRgbColor() {
  
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  
  return `rgb(${r}, ${g}, ${b})`;
}

export function generateShadesOfColor(color, saturation, brightness, numShades) {

  let stringColors = {
    'red': 0,
    'orange': 30,
    'yellow': 60,
    'lime-green': 90,
    'green': 120,
    'cyan': 180,
    'sky-blue': 200,
    'blue': 240,
    'purple': 270,
    'pink': 300
  };

  let colorNumber = color;
  if(typeof color === 'string'){
    colorNumber = stringColors[color];
  }

  if (colorNumber < 0 || colorNumber > 360 || saturation < 0 || saturation > 100 || brightness < 0 || brightness > 100) {
    throw new Error('color must be between 0 and 360, and Saturation between 0 and 100.');
  }

  const shades = [];
  const step = 100 / numShades; 

  for (let i = 0; i < numShades; i++) {
    const lightness = brightness + i * step;
    const shade = `hsl(${colorNumber}, ${saturation}%, ${lightness}%)`;
    shades.push(shade);
  }

  return shades;
}

/* ------------------- DAY FUNCTIONS --------------------------*/


export const sortedDays = (arr, key = 'day', missingDays) => {
  let sortedArr = arr.sort((a,b) => a[key] > b[key] ? 1 : -1);
  if(missingDays){
    let adddedArr =  addDays(sortedArr, key);
    return adddedArr;
    // return groupDuplicate(adddedArr, key);
  }
  return groupDuplicate(sortedArr, key);
};

export function addDays (arr, key) {
  let groupedArr = groupDuplicate(arr, key);
  let newArr = [];
  for ( let i = 0; i <= groupedArr.length; i++) {
  
    if(newArr.find(item => item[key] === groupedArr[i][key])){
      continue;
    }
    let currentDay = groupedArr[i];
    let nextDay = groupedArr[i+1];
    
    if(!nextDay){
      newArr.push(currentDay);
      break;
    }
    newArr = newArr.concat(isSame(currentDay, nextDay, key));
  }

  return newArr;
}

export function isSame (firstDay, secondDay, key, acc) {
  let first = dayjs(firstDay[key]).format('YYYY-MM-DD');
  let second = dayjs(secondDay[key]).format('YYYY-MM-DD');
  let addedDays = acc ? acc : [firstDay];
  // console.log(first, second);
  if(dayjs(first).isSame(second) || dayjs(first).add(1, 'day').isSame(second)) return addedDays;
  let addedDay = dayjs(first).add(1, 'day').format('YYYY-MM-DD');
  let obj = {
    [key]: addedDay,
    'total_ars': 0
  };

  return isSame(obj, secondDay, key, [...addedDays, obj]);
}

export function groupDuplicate (arr, key) {
  let newArr = [];
  for(const item of arr){
    let found = newArr.find(it => it[key] === item[key]);
    let foundIndex = newArr.findIndex(it => it[key] === item[key]);
    if(found) {
      let obj = {
        coin: found.coin,
        total_ars: found?.total_ars + item?.total_ars,
        day: found.day,
        approved_orders: found?.approved_orders + item?.approved_orders
      };
      newArr[foundIndex] = obj;
    } else {
      newArr.push(item);
    }
  }
  return newArr;
}



export function dayTotal (arr, key) {
  return arr?.filter(item => item[key]);
}

export function dayFormatter (str) {
  return dayjs(str).format('MMM-DD');
}

/* ------------------- GENERAL FUNCTIONS --------------------------*/

export function labelFormatter (array, key, _formatter) {
  return array?.map((obj, index) => _formatter(obj[key]));
}


export function createLineDataset (key, array, options = {}, isTotal, includeNull) {
  let sortedArr = sortedDays(array, 'day', includeNull);
  const extractedCoins = extractCoins(sortedArr, 'coin');
  let color = [generateRandomRgbColor()];
  if(isTotal) {
    let total = sumTotalArray(sortedArr, key, includeNull);

    return [{
      label: 'Total',
      data: total,
      borderColor: color,
      backgroundColor: color,
      tension: 0.2,
      borderWidth: 3,
      hoverBorderWidth: 3,
      hoverOffset: 4,
      ...options
    }];
  }
  let arr = Array.from({ length: extractedCoins.length}, (_, index) => {
    return {
      label: extractedCoins[index],
      data: sortedArr.filter(item => item.coin === extractedCoins[index]).map(item => item[key]),
      borderColor: color,
      tension: 0.2,
      borderWidth: 3,
      hoverBorderWidth: 3,
      hoverOffset: 4,
      ...options
    };
  });
  return arr;
}


export function extractCoins (array, key) {
  let arr = [];
  array?.map(item => !arr.includes(item[key]) && arr.push(item[key]));
  return arr;
}

export function coinSum (arr,ticker, key) {
  return arr.reduce((a, b, index) => {
    if(b?.coin === ticker) {
      return a + b[key];
    }
    return a;
  }, 0);
}

export function sumTotal (arr, key) {
  
  return arr.reduce((a,b,index) => {
    if(key) {
      return a + b[key];
    } else {
      return a + b;
    }
  }, 0);
}
export function sumTotalArray (arr, key) {
  return arr.reduce((a,b,index) => {
    return [...a, b[key]];
  }, []);
}

export function createPieDataset (key, arr, options = {}) {
  const extractedCoins = extractCoins(arr, 'coin');
  
  let array = Array.from({ length: extractedCoins.length}, (_, index) => {
    return coinSum(arr, extractedCoins[index], key);
  });
  let colors = generateShadesOfColor(100, 80, 5, extractedCoins.length);

  return [{
    label: 'Total',
    data: array,
    backgroundColor: colors,
    borderColor: colors,
    hoverOffset: 4,
    ...options
  }];
} 