export const COLORS = {
  primary:'#8502C2',
  table:{
    headerSorteable:'#7C7C7C',
    indicators:{
      background:{
        approved:'#BCF8DB',
        rejected:'#F19595',
        pending:'#FEFFC7',
        not_submitted:'#D9D9D9',
        draft:'#BCD7F8',
        inProgress:'#F7C7FF'
      },
      fontColor:{
        approved:'#21B141',
        rejected:'#B12121',
        pending:'#CCB802',
        not_submitted:'#515151',
        draft:'#2149B1',
        inProgress:'#8502C2'
      }
    }
  }
};