import React, { useEffect } from 'react';
import { Layout, useRedirect } from 'react-admin';
import MenuCustom from '../MenuCustom/MenuCustom';
import { AppbarCustom } from '../AppbarCustom/AppbarCustom';

const LayoutCustom = (props) => {
  const redirect = useRedirect();

  useEffect(() => {
    
    let wrongHref = window.location.href.split('/');
    if(wrongHref){
      if (wrongHref[4] === 'MI%20NEGOCIO') return redirect('payment_requests');
    }
  }, []);
  return (
    <Layout {...props} menu={MenuCustom} appBar={AppbarCustom} sx={{
      '& .RaLayout-content':{
        backgroundColor:'#fff'
      },
      '& .RaSidebar-fixed': {
        borderRight: '1px solid',
        borderColor: '#cdcdcd',
        backgroundColor: '#F0F0F0',
        height: '100%'
      },
      '& .MuiDrawer-docked': {
        borderRight: '1px solid',
        borderColor: '#cdcdcd',
        backgroundColor: '#F0F0F0'
      },
      '& .MuiList-root':{
        padding:'0px'
      }
    }}/>
  );
};

export default LayoutCustom;