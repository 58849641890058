import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import Card from '../../Components/Card/Card';
import { useCurrencies } from '../../utils/helpers/useCurrencies';

const List = () => {
  const dataProvider = useDataProvider();
  const [reports, setReports] = useState(null);
  const { currencies, loading, error } = useCurrencies();

  useEffect(() => {
    dataProvider.getResource('reports')
      .then(({ data }) => setReports(data))
      .catch(() => console.log('error'));
  }, []);

  if (reports) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', width: '690px', flexDirection: 'column', margin: '60px' }}>
          <Box sx={{ display: 'flex', flex: 1, gap: 4, width: '100%' }}>
            <Card title={'Cobros en pesos (ARS)'} sx={{ padding: '0px 21px 12px 18px' }}>
              <Typography sx={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>$ {reports.total_ars}</Typography>
            </Card>
            <Card title={'Pagos aprobados'} sx={{ padding: '0px 21px 12px 18px' }}>
              <Typography sx={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>{reports.payment_requests_approved}</Typography>
            </Card>
          </Box>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Card title={'Cobros por token'} sx={{ padding: '0px 21px 12px 18px', width: '100%' }} sxHeader={{ width: '100%' }}>
              {currencies?.map((token)=> {
                return (
                  <ItemList
                    key={token?.id}
                    icon={token?.logo}
                    title={token?.name}
                    chain={token?.chain?.name}
                    valueARS={Math.trunc(reports.totals_per_coin?.[token?.ticker].ars)}
                    valueCrypto={reports.totals_per_coin?.[token?.ticker].crypto}
                    slice={4}
                  />
                );
              })}
            </Card>
          </Box>
        </Box>
      </Box>
    );
  }
};

const ItemList = ({ icon, title, chain, valueARS, valueCrypto, slice = 10 }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '14px 0', width: '100%'}}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 2 }}>
        <img src={icon} style={{ width: '24px', height: '24px' }} />
        <div style={{display:'flex',flexDirection:'column'}}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{title}</Typography>
          <span style={{ fontSize: '13px', fontWeight: 400 }}>{chain}</span>
        </div>
      </Box>
      <Typography sx={{ flex: 2, fontSize: '16px', fontWeight: '600', }}>$ {valueARS.toLocaleString('es-ES')}</Typography>
      <Typography sx={{ flex: 2, textAlign: 'left', fontSize: '16px', fontWeight: '600' }}>{valueCrypto.toString().slice(0, slice)} <span style={{ fontSize: '12px', fontWeight: '600' }}>{title}</span></Typography>
    </Box>
  );
};

export default List;