import { defaultTheme } from 'react-admin';
import ProximaNova from '../assets/Font/Proxima Nova Regular.otf';

const ProximaNovaFont = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${ProximaNova}) format('truetype')`,
};

export const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    mode: 'light',
    primary: {
      main: '#8502C2',
    },
    secondary: {
      main: '#8448A0',
    },
    background: {
      default: '#ededed',
      paper: '#ededed',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Open-sans',
      'Proxima Nova',
      '-apple-system',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // invisible border when not active, to avoid position flashs
          borderLeft: '4px solid transparent',
          padding: '12px 12px 12px 12px',
          fontSize: '14px',
          fontWeight: '600',
          '&.RaMenuItemLink-active': {
            borderLeft: '4px solid #8448A0',
            color: '#8448A0',
            '& .RaMenuItemLink-icon': {
              color: '#8448A0',
            },
          },
          '& .RaMenuItemLink-icon': {
            color: '#5a5a5a',
          },
        },
      },
    },
  },
};
