import Actividad from '../../Resource/Actividad';
import Dashboard from '../../Resource/Dashboard';
import Merchants from '../../Resource/Merchants';
import NetworkLink from '../../Resource/NetwrokLink/NetworkLink';
import Reports from '../../Resource/Reports';
import Settings from '../../Resource/Settings';
import User from '../../Resource/User';

export const PERMISSIONS = {
  superadmin: {
    resources: [
      {
        name: 'dashboard',
        label: 'Dashboard',
        component: Dashboard,
      },
      { name: 'payment_requests', label: 'Movimientos', component: Actividad },
      // {
      //   name: 'NEGOCIOS',
      //   isSeparator: true,
      // },
      { name: 'merchants', label: 'Comercios', component: Merchants },
      { name: 'admins', label: 'Usuarios', component: User },
    ],
    customRoutes: [],
  },
  admin: {
    resources: [
      {
        name: 'dashboard',
        label: 'Dashboard',
        component: Dashboard,
      },
      {
        name: 'MI NEGOCIO',
        isSeparator: true,
      },
      { name: 'payment_requests', label: 'Movimientos', component: Actividad },
      {
        name: 'MI CUENTA',
        isSeparator: true,
      },
      { name: 'admins', label: 'Usuarios', component: User },
      { name: 'merchants', label: 'Configuración', component: Settings },
      { name: 'reports', label: 'Reporte', component: Reports },
    ],
    customRoutes: [
      { path: '/network_link', component: NetworkLink },
    ],
  },
  operator: {
    resources: [
      { name: 'payment_requests', label: 'Movimientos', component: Actividad },
    ],
    customRoutes: [],
  },
};
