import { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '../Generics/TextField';

export const SearchComponent = ({
  sx,
  placeholder,
}) => {
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = useState(filterValues?.q);
  const handleFilter = (e) => {
    setFilters && setFilters({ ...filterValues, q: e.target.value });
    setValue(e.target.value);
  };
  useEffect(() => {
    setTimeout(() => {
      setFilters({ q: null });
      setValue('');
    },[1000]);
  }, []);
  return (
    <TextField
      sx={{ width: '100%', backgroundColor: 'white', ...sx }}
      value={value}
      onChange={handleFilter}
      placeholder={placeholder}
      icon={<SearchIcon sx={{ color: 'rgba(0,0,0,.5)', fontSize: '21px' }}/>}
    />
  );
};