import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Menu, useResourceDefinitions, useSidebarState, MenuItemLink } from 'react-admin';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import logo from '../../../assets/Images/icon.png';
import {getToken} from '../../../utils/helpers/getToken';

const MenuCustom = () => {
  const [merchant, setMerchant] = useState({});
  const tokenId = localStorage.getItem('auth');
  const cleanToken = tokenId.replace(/^['"]+|['"]+$/g, '');
  const tokenRole = getToken()?.role;
  const sidebarOpen = useSidebarState();
  const recursos = useResourceDefinitions();
  const resources = Object.keys(recursos).map(
    (name) => recursos[name],
  );
  const openAndClose = () => {
    if (sidebarOpen[0]) {
      sidebarOpen[1](false);
    } else {
      sidebarOpen[1](true);
    }
  };
  useEffect(() => {
    const token = getToken();
    const getMerchantData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_PATH}/merchants/${token.id}`,{
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Expose-Headers': 'content-range',
              Authorization:  `Bearer ${cleanToken}`,
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Content-Type': 'application/json',
            },
          }
        );
        const data = await res.json();
        setMerchant(data);
      } catch (error) {
        console.log(error);
      }
    };
    if(token.role !== 'superadmin'){
      getMerchantData();
    }
  }, []);
  return (
    <Menu sx={{ paddingTop: '42px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: '#808080',
          paddingY: '1em',
          gap: sidebarOpen[0] ? 0 : 0,
          height: '100%',
          // margin:'1em 0em'
        }}
      >
        {sidebarOpen[0] ? (
          <div
            style={{
              width: '100%',
              paddingLeft: '25px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <img
              src={
                logo
              }
              alt="logo"
              style={{ width: '25%' }}
            />
            <div style={{ width: '75%', height: '100%' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                {tokenRole === 'admin' ? merchant?.name : 'SimpleFi'}
              </Typography>
            </div>
          </div>
        ) : null}
        <IconButton
          size="small"
          onClick={() => openAndClose()}
        >
          {
            sidebarOpen[0] ? (
              <MdKeyboardArrowLeft size={30} color='#000' />
            ) : (
              <MdKeyboardArrowRight size={24}color='#000' />
            )
          }
        </IconButton>
      </Box>
    
      {/* <Menu.ResourceItems /> */}
      {resources?.map((resource,index) => {
        if(index === 0 && resource?.name === 'dashboard'){
          return (
            <Dashboard resource={resource} index={index} key={`dashboard-${resource.name}-${index}`}/>
          );
        };
        if(resource?.options?.isSeparator){
          return (
            <Separator resource={resource} index={index} key={`separator-${resource.name}-${index}`}/>
          );
        }else{
          return (
            <MenuItemLink
              id={resource?.name}
              key={`link-${resource.name}-${index}`}
              to={
                resource?.options.customPath
                  ? `/${resource.options.customPath}`
                  : `/${resource.name}`
              }
              primaryText={
                (resource.options && resource.options.label) ||
                              resource.name
              }
              leftIcon={
                resource.icon ? (
                  <resource.icon
                    color={'#000000'}
                  />
                ) : null
              }
              sidebarIsOpen={sidebarOpen[0]}
            />
          );
        }
        
      } )}
    </Menu>
  );
};

export const Dashboard = ({resource,index})=>{
  const sidebarOpen = useSidebarState();
  return (<>
    <Box
      sx={{
        height: sidebarOpen[0] ? '80px' : 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: '#808080',
      }}
    >
      <MenuItemLink
        key={`dashboard-${resource.name}-${index}`}
        sx={{
          width:'100%'
        }}
        to={
          resource.options.customPath
            ? `/${resource.options.customPath}`
            : `/${resource.name}`
        }
        primaryText={
          (resource.options && resource.options.label) ||
                              resource.name
        }
        leftIcon={
          resource.icon ? (
            <resource.icon/>
          ) : null
        }
      />
    </Box>
  </>
  );
};
          
  

export const Separator = ({resource,index})=>{
  const sidebarOpen = useSidebarState();
  return (
    <Box sx={{display:sidebarOpen[0] ? 'flex' : 'none'}}>
      <Typography key={`separator-${resource.name}-${index}`} sx={{fontSize:'14px', lineHeight:1.75, letterSpacing:'0.01em', fontWeight:600, color:'rgb(81, 81, 81)', padding:'12px'}}>{resource.name}</Typography>
    </Box>
  );
};
export default MenuCustom;