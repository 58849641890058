import React from 'react';
import { TextInput, SelectInput, ReferenceInput } from 'react-admin';
import CreateWrapper from '../../Components/Form/CreateWrapper';
import Card from '../../Components/Card/Card';
import { Box } from '@mui/material';
import Form from './Form';

const Create = () => {
  return (
    <CreateWrapper>
      <Form />
    </CreateWrapper>
  );
};

export default Create;