import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { useAuthProvider, useLogin, useRedirect } from 'react-admin';
import wallpaper from '../../assets/Images/simplefi_background.png';

const invalidMessage = {
  data: { msg: 'This link was already used before, please try another one.', status: 'error' },
  res: { ok: true }
};

const statusMessage = {
  201: {
    data: { msg: 'A verification email has been sent to your email address.', status: 'success' },
    res: { ok: true }
  },
  404: {
    data: { msg: 'Email address not found.', status: 'error' },
    res: { ok: true }
  }
};

const LoginScreen = () => {
  const [toggleButton, setToggleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const login = useLogin();
  const { emailAuth } = useAuthProvider();
  const hasTokenUrl = window.location.href.includes('token_url');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setToggleButton(true);
    const email = data.get('email');

    emailAuth({ email }).then(r => {
      setShow(r);
    }).catch(e => {
      setShow({ status: 404, data: { detail: 'No encontramos ningún usuario con tu email' } });
    }).finally(() => setToggleButton(false));
  };

  const AuthWithToken = () => {
    // const apiUrlParams = new URLSearchParams(props.location.search);
    const token_url = document.location.hash.split('login')[1].slice(11);
    const sendTokenUrl = decodeURIComponent(token_url.replace(/\+/g, ' '));

    if (sendTokenUrl == null) {
      return;
    }
    login(sendTokenUrl).then((d) => {
      setLoading(false);
    }).catch(e => {
      setShow(invalidMessage);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (hasTokenUrl) {
      setLoading(true);
      AuthWithToken();
    }
    // else {
    //   localStorage.getItem('auth') && redirect('/transactions');
    // }
  }, [hasTokenUrl]);


  return (
    <Box style={{ overflow: 'hidden' }}>
      {/* {loading ? <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size='55px' variant="indeterminate" />
      </Box> : ( */}
      <Grid container component="main" sx={{ height: '100vh' }} display={loading && 'none'}>
        <Grid item xs={false} sm={4} md={7}>
          <img src={wallpaper} style={{ width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
              SimpleFi BackOffice
            </Typography>
            <Box component="form" onSubmit={handleSubmit} style={{ marginTop: '10px', width: '300px' }}>
              <TextField
                disabled={toggleButton}
                margin="normal"
                variant='outlined'
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {
                show ?
                  <Alert variant="filled" severity={show.status === 201 ? 'success' : 'error'}>
                    {show.data.detail}
                  </Alert>
                  : null
              }
              <Button
                disabled={toggleButton}
                fullWidth
                variant="contained"
                type='submit'
                color='primary'
                style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
              >
                {toggleButton ?
                  <CircularProgress size={24} /> :
                  <Typography style={{ textAlign: 'center', width: '100%' }} variant='subtitle2'>Sign In</Typography>
                }
              </Button>

              <Grid container justifyContent='flex-end'>
                <Grid item display="flex" flexDirection="column">
                  {/* <Link href="#/" variant="body2" textAlign="end" onClick={() => { setToggleButton(null); }}>
                    {'Volver a iniciar sesión'}
                  </Link> */}
                  {/* <Link href="#" variant="body2">
                    {'No tenes cuenta? Habla con Soporte'}
                  </Link> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </Box>
  );
};

export default LoginScreen;