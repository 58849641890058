import { usePermissions, useResourceContext } from 'react-admin';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
// Material & Icons
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip, Button } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

export const CsvDownloader = ({ list, sx, minWidth }) => {
  const onlyIcon = useMediaQuery(minWidth ? minWidth : '(max-width:1060px)');
  const [open, setOpen] = useState(false);
  const params = useParams();
  const permis = usePermissions()?.permissions !== 'talent_manager';
  const resource = useResourceContext();

  const handlerOpen = () => {
    setOpen(true);
  };
  const {downLoadCsv} = handleDownloadCsv({ resource });

  const styleButton =
    list && onlyIcon
      ? {
        fontSize: '.8em',
        fontWeight: '600',
        height: 'auto',
        minWidth: '260px',
      }
      : {
        width: '130px',
        fontWeight: '600',
        display: 'flex',
        height: '44px',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        textTransform: 'none',
        gap: '10px',
        color: '#8A8A8A',
        fontSize: '15px',
      };
  return (
    <>
      {permis && (
        <div
          style={{
            display: 'flex',
            ...sx,
          }}
        >
          <Tooltip title={onlyIcon ? 'Export CSV' : ''} placement="top">
            <Button
              onClick={downLoadCsv}
              sx={styleButton}
              startIcon={onlyIcon && <ExportSvg />}
            >
              {!onlyIcon && (
                <>
                  <ExportSvg />
                  <span>Exportar</span>
                </>
              )}
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
};

const handleDownloadCsv = (props) => {
  const {resource} = props;
  const apiUrl = process.env.REACT_APP_BASE_PATH;
  const token =localStorage.getItem('auth')
  const cleanToken = token.replace(/^['"]+|['"]+$/g, '');
  
  const downLoadCsv = async () => {
    let url;
    url = `${apiUrl}/${resource}/export_to_csv`;
    try {
      let response = await axios({
        method: 'GET',
        url: url,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization:  `Bearer ${cleanToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'text/csv',
        },
        responseType: 'arrayBuffer',
      });
      if (response) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'downloaded_file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log('error', 'There was an error, please try again later.');
      throw new Error(error);
    }
  };
  
  return {
    downLoadCsv
  };
};
const ExportSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.92 15.62C20.8694 15.4988 20.7983 15.3872 20.71 15.29L17.71 12.29C17.5217 12.1017 17.2663 11.9959 17 11.9959C16.7337 11.9959 16.4783 12.1017 16.29 12.29C16.1017 12.4783 15.9959 12.7337 15.9959 13C15.9959 13.2663 16.1017 13.5217 16.29 13.71L17.59 15H12C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17H17.59L16.29 18.29C16.1963 18.383 16.1219 18.4936 16.0711 18.6154C16.0203 18.7373 15.9942 18.868 15.9942 19C15.9942 19.132 16.0203 19.2627 16.0711 19.3846C16.1219 19.5064 16.1963 19.617 16.29 19.71C16.383 19.8037 16.4936 19.8781 16.6154 19.9289C16.7373 19.9797 16.868 20.0058 17 20.0058C17.132 20.0058 17.2627 19.9797 17.3846 19.9289C17.5064 19.8781 17.617 19.8037 17.71 19.71L20.71 16.71C20.8026 16.6161 20.8742 16.5036 20.92 16.38C21.02 16.1365 21.02 15.8635 20.92 15.62ZM14 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H11V7C11 7.79565 11.3161 8.55871 11.8787 9.12132C12.4413 9.68393 13.2044 10 14 10H18C18.1974 9.99901 18.3901 9.93961 18.5539 9.82928C18.7176 9.71895 18.845 9.56262 18.92 9.38C18.9966 9.19789 19.0175 8.99718 18.9801 8.80319C18.9428 8.6092 18.8488 8.43062 18.71 8.29L12.71 2.29C12.6273 2.21222 12.5328 2.14808 12.43 2.1H12.34L12.06 2H6C5.20435 2 4.44129 2.31607 3.87868 2.87868C3.31607 3.44129 3 4.20435 3 5V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H14C14.2652 22 14.5196 21.8946 14.7071 21.7071C14.8946 21.5196 15 21.2652 15 21C15 20.7348 14.8946 20.4804 14.7071 20.2929C14.5196 20.1054 14.2652 20 14 20ZM13 5.41L15.59 8H14C13.7348 8 13.4804 7.89464 13.2929 7.70711C13.1054 7.51957 13 7.26522 13 7V5.41Z"
        fill="#8A8A8A"
      />
    </svg>
  );
};
