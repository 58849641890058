import { TextInput, useDataProvider, useGetList, useGetOne, useRecordContext } from 'react-admin';
import Card from '../../../Components/Card/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { initialCurrencies, parseCurrencies } from '../functions';
import { SlSettings } from 'react-icons/sl';
import CardTokenCheck from '../../../Components/Input/CardTokenCheck';
import { Box, Button, Collapse, Divider, IconButton, Popover, Skeleton, Tooltip, Typography } from '@mui/material';
import styles from './../../../Components/PaymentOptions/index.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';

import InputLabel from '../../../Components/Input/InputLabel';
import { grey } from '@mui/material/colors';
import { theme } from '../../../Theme';

const requestHeaders = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'rank', order: '' },
};


export const PaymentMethods = ({ isCreate }) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [currencies, setCurrencies] = useState(record ? initialCurrencies(record.currencies) : []);



  // Porque se hace get de currencies solo en el create, no puede el merchant agregar coins que no tiene?

  const { data, isFetching, refetch } = useQuery(['getCurrencies'], async () => {
    try {
      const response = await dataProvider.getCurrencies(requestHeaders);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }, {
    onSuccess: ({ data }) => setCurrencies(parseCurrencies(data.results)),
    onError: () => console.log('Error currencies'),
    enabled: !!isCreate
  });

  return (
    <Fragment key="coins-fragment">
      <Card border isLoading={isFetching} sx={{display: 'flex', flexDirection:'column', gap: '30px'}} title="Tokens">
        {
          currencies.find(item => !item.evm_compatible) && 
         <BitCoins 
           setCurrencies={setCurrencies} 
           currencies={currencies} 
         />}
        <Divider />
        <EVM currencies={currencies} setCurrencies={setCurrencies} />
      </Card>
    </Fragment>
  );
};

export const EVM = ({ currencies, setCurrencies }) => {
  const [open, setOpen] = useState(true);
  const handleOpenDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      gap: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #d6d6d6'
    }}>
      <Box sx={{width: '100%'}}>
        <InputLabel 
          labelSx={{
            width: '30vw',
            marginBottom: '0px',
            fontSize: '.9em',
            fontWeight: '600',
            textTransform: 'uppercase'
          }}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap:'20px'
          }} label="EVM coins address por default" input={
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Box
                sx={{
                  width: '100%',
                }}
              >

                <TextInput
                  fullWidth
                  source={'default_evm_address'}
                  label={''}
                  sx={{
                    marginBottom: 0,
                    width: '80%'
                  }}
                  FormHelperTextProps={{
                    sx: {
                      display: 'none'
                    }
                  }}
                  onChange={(e) => console.log(e.target.value)}
                />
                <TooltipButton message="Los tokens que habilites llegarán a esta dirección salvo que especifiques una particular para algún token" />
              </Box>
              <Tooltip title="Personalize every coin">
                <IconButton variant="outlined" sx={{justifySelf: 'flex-end'}} onClick={handleOpenDrawer}>
                  {open ?  <IoIosArrowDropupCircle sx={{fontSize: '21px'}} /> :<IoIosArrowDropdownCircle />}
                </IconButton>
              </Tooltip>
            </Box>
          }
        />
        <Collapse in={open}>
          <CurrenciesBox currencies={currencies} setCurrencies={setCurrencies} evm /> 
        </Collapse>
      </Box>
    </Box>
  );
};

export const CurrenciesBox = ({ currencies, setCurrencies, evm }) => {
 
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '20px 0',
    }}>
      {/* <div className={styles['card-token-grid']} >

        <InputLabel label=""/>
        <InputLabel label=""/>
        <InputLabel label="Chain"/>
        <InputLabel label="Address"/>
        <InputLabel label="Habilitado"/>
      </div> */}
      <ChainCard currencies={currencies} setCurrencies={setCurrencies} evm={evm} />
    </Box>
  );
};

export const ChainCard = (props) => {
  const {
    currencies,
    evm,
    setCurrencies
  } = props;

  const chains = useMemo(() => {
    let arr = [];
    if (currencies.length > 0 ) {
      currencies?.map(item => {
        if((evm ? item?.evm_compatible : !item?.evm_compatible) && !arr.find(it => it?.chain_id === item?.chain_id)){
          arr.push({
            chain_id: item.chain_id,
            chain_name: item.chain_name,
            chain_logo: item.chain_logo
          });
        }
      });
    }

    return arr;
  },[currencies]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '30px'
    }}>
      {
        chains && chains.map(item =>(
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'auto',
            gap: '10px',
          }}>
            <Box sx={{
              padding: '10px',
              display: 'grid',
              gridTemplateColumns: '100px 50px 3fr 1fr 1fr',
              justifyItems: 'flex-start',
              alignItems: 'center',
            }}>
              <Typography sx={{
                fontSize: '.8em',
                fontWeight: '600',
                textTransform: 'uppercase',
                color: theme.palette.primary.main
              }}>{item?.chain_name}</Typography>
              <img style={{width: '20px'}} src={item.chain_logo} />
              <InputLabel label="Chain"/>
              <InputLabel label="Address"/>
              <InputLabel label="Habilitado" sx={{justifySelf: 'flex-end'}} />
            </Box>
            { 
              currencies?.map((token, key) => {
                if((evm ? token?.evm_compatible : !token?.evm_compatible) && token?.chain_id === item?.chain_id) {
              
                  return (
                    <CardTokenCheck
                      source={`currencies.[${token.index}]`}
                      token={token}
                      key={key}
                      index={key}
                      currencies={currencies}
                      setCurrencies={setCurrencies}
                      noDefault={evm}
                    />
                  );
                }
              })
                 
            }
          </Box>
        ))
      }
    </Box>
  );
  
};

export const TooltipButton = ({ message }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ width: '40px', height: '40px'}} aria-label="delete" color='secondary' onClick={handleClickMenu}>
        <HelpOutlineIcon sx={{fontSize: '21px'}} />
      </IconButton>
      <Popover
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box sx={{
          maxWidth: '320px',
          backgroundColor: '#fff',
          padding: '15px 18px'
        }}>
          <Typography 
            variant='subtitle2' 
            style={{fontWeight: '400', color: grey[600]}}>{message}</Typography>
        </Box>
      </Popover>
    </>
  );
};

export const BitCoins = ({setCurrencies, currencies }) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginTop: '20px'
    }}>
      <InputLabel labelSx={{
        fontSize: '.9em',
        fontWeight: '600',
        textTransform: 'uppercase'
      }} label="Bitcoin" />
      <ChainCard currencies={currencies} setCurrencies={setCurrencies} />

    </Box>
  );
};