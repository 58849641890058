
import { defaultPieOptions } from './defaults';
import { createPieDataset, dayFormatter, extractCoins, labelFormatter } from '../Functions';
import { useGetStatistics } from '../Hooks';
import { Box } from '@mui/material';
import { Charts } from './Charts';
import { useEffect, useMemo, useState } from 'react';


export const ChartPieComponent = (props) => {
  const [resultData, setResultData] = useState({});
  const { 
    filters = {},
    options = {},
    sx
  } = props;
  const { refetch, isFetching } = useGetStatistics({
    filters: filters,
    onSuccess: ({data: {results}}) => {
      const datasets = createPieDataset('total_ars', results?.[0]?.days);
   
      const data = {datasets, labels: extractCoins(results?.[0]?.days, 'coin')};
 
     
      setResultData(data);
    }
  });

  useEffect(() => {
    refetch();
    return () => {
      setResultData({});
    };
  },[filters]);

  const config = useMemo(() => {
    return {
      isLoading: isFetching,
      type: 'doughnut',
      data: resultData,
      options: {...defaultPieOptions,  ...options}
    };
  },[resultData, isFetching]);

  return (
    <Charts {...config} />
  );
};