import React from 'react';
import CustomList from '../../Components/List/CustomList';
import { TextField } from 'react-admin';
import HeaderList from '../../Components/HeaderList/HeaderList';

const List = () => {
  return (
    <CustomList
      paramsList={{
        perPage: 50,
        sort: { field: 'date_created', order: 'ASC' },
        actions: <HeaderList roles hasSearch hasCreate />,
      }}
      styleDatagrid={{
        '& .column-name': {
          width: '30%',
        },
        '& .column-email': {
          textAlign: 'center',
          width: '40%',
        },
        '& .column-role': {
          textAlign: 'right',
          width: '30%',
        },
      }}
    >
      <TextField label={'Nombre'} source={'name'} />
      <TextField label={'Email'} source={'email'} />
      <TextField label={'Rol'} source={'role'} />
    </CustomList>
  );
};

export default List;
